.nav__primary-tabs {
  margin: 15px;
  margin-top: 20px;
}

.nav__content {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 40px;
}

.nav__title {
  @include h2;
  margin-bottom: 5px;

  &:focus {
    outline: $focus-outline;
  }
}

.nav__description {
  margin-bottom: 25px;
}

.nav__more {
  margin-top: 40px;
}

.nav__more__title {
  @include h3;
  margin-bottom: 5px;
}

.nav__more__description {
  margin-bottom: 12px;

  a {
    svg {
      fill: $blue;
      vertical-align: middle;
      margin-left: 3px;
    }
  }
}

.nav__parameters__list {
  margin-bottom: 20px;
}