.chart-error {
  border: 2px solid $red;
  border-radius: 3px;
  padding: 10px;
  overflow: hidden;
}

.chart-error__body {
  @include body;
  color: $red;

  @include breakpoint(min $breakpoint) {
    float: left;
  }
}

.chart-error__retry {
  @include link;

  @include breakpoint(min $breakpoint) {
    float: right;
  }
}