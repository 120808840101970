@mixin body {
  h2 {
    @include h1;
    color: $title-blue;
    margin-bottom: .5em;
  }

  h3 {
    @include h2;
    color: $title-blue;
    margin-bottom: .5em;
  }

  h4 {
    @include h3;
    color: $title-blue;
    margin-bottom: .5em;
  }

  h5 {
    @include h4;
    color: $text;
    margin-bottom: .5em;
  }

  h6 {
    @include h5;
    color: $text;
    margin-bottom: .5em;
  }

  li,
  p {
    line-height: 1.5;
    margin-bottom: .5em;
    font-size: $text-l;

    @include breakpoint(min $breakpoint) {
      font-size: $text-xl;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  p + h2,
  p + h3,
  p + h4,
  p + h5,
  p + h6 {
    margin-top: 1.25em;
  }


  /**
   * List styling, mostly copied from rws.nl - typography.scss
   */

  ul,
  ol,
  dl {
    margin-bottom: 1.5em;
  }

  ul {
    margin-left: 1em;
    list-style: none;

    > li {
      &:before {
        content: '›';
        float: left;
        margin-left: -1em;
      }
    }
  }

  ol {
    margin-left: 1.6em;
  }

  ul,
  ol {
    line-height: 1.5;

    li {
      display: list-item;

      ul,
      ol {
        margin-bottom: 0;
        font-size: 1em; /* Override nested font-size change */
      }
    }
  }

  /**
   * Table styling, mostly copied from rws.nl - tables.scss
   */
  table {
    width: 100%;
    margin-bottom: 1em;

    caption {
      background: #e8e7e5;
      padding: .7em 15px;
      text-align: left;
      font-size: 1.3em;
      border-bottom: solid 2px #fff;
      margin: auto -3px;
      border-radius: 3px;
    }

    th {
      font-weight: bold;
    }

    td,
    th {
      background: #f7f6f4;
      padding: .7em 15px;
      text-align: left;
    }

    thead {
      td,
      th {
        background: #e8e7e5;
        border: solid 1px #dad9d7;
        font-weight: bold;
      }
    }

    tbody {
      td,
      th {
        vertical-align: top;
        border: solid 1px #dad9d7;
      }
    }

    tr {
      &:first-child {
        & > td,
        & > th {
          border-top: 0;
        }
      }

      &:last-child {
        & > td,
        & > th {
          border-bottom: 0;
        }
      }

      & > :first-child {
        border-left: 0;
      }

      & > :last-child {
        border-right: 0;
      }
    }
  }
}

.body {
  @include body;
}