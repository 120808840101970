/**
 * zooming element for map
 */

.zoom {
  font-weight: bold;
  text-align: center;
  color: $text-grey;
  box-shadow: 0 1px 3px 0 rgba(black, .4);
  border: 1px solid $border-grey2;
  border-radius: 3px;
  overflow: hidden;
  width: 30px;
  background-color: white;
}

/**
 * zooming buttons
 */

.zoom__btn {
  width: 30px;
  height: 30px;
  text-align: center;
  background-color: white;
  transition: background-color $timing;

  svg {
    transition: opacity $timing;
  }

  &:hover {
    background-color: $white-hover;

    .zoom__btn__icon {
      fill: $text;
    }
  }

  &:first-child {
    border-bottom: 1px solid $border-grey2;
  }

  &:focus .zoom__btn__icon {
    outline: $focus-outline;
    fill: $text;
  }

  &.is-inactive {
    &:hover {
      background-color: transparent;
    }

    .zoom__btn__icon {
      opacity: .5;
    }
  }

  &[disabled] {
    svg {
      opacity: .5;
    }
  }
}


.zoom__btn__icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  fill: $text-grey2;
  transition: opacity $timing, fill $timing;
}