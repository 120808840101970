// Breakpoint mixin
@mixin breakpoint($first, $second: false) {


  // One given value will compile to min-width
  @if length($first) == 1 and $second == false {
    @media (min-width: $first) { @content; }
  }

  // Both given values will compile to min-width & max-width
  @if $first and $second {

    // Subtract 1px on max-width so queries don't conflict
    $second: $second - 1px;

    @media (min-width: $first) and (max-width: $second) { @content; }
  }

  $keyword: nth($first, 1);

  // Parameter 'min' will compile value to min-width
  @if $keyword == min {
    $value: nth($first, 2);
    @media (min-width: $value ) { @content; }
  }

  // Parameter 'max' will compile value to max-width
  @if $keyword == max {

    // Collect the actual pix value from the parameters
    $value: nth($first, 2);

    // Subtract 1px on max-width so queries don't conflict
    $value: $value - 1px;

    @media (max-width: $value ) { @content; }
  }
}


@mixin hidpi($ratio: 1.3) {
  @media only screen and (-webkit-min-device-pixel-ratio: $ratio),
  only screen and (min-resolution: #{round($ratio*96)}dpi),
  only screen and (min-resolution: #{$ratio}dppx) {
    @content;
  }
}