.btn--secondary {
  display: block;
  background-color: $yellow;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 15px;
  border-radius: 3px;
  font-size: 18px;
  transition: opacity $timing, background-color $timing;

  &.sm-text{
    font-size: 14px;
  }

  &:focus {
    outline: $focus-outline;
  }

  &:hover {
    background-color: $yellow-hover;
  }

  &:disabled {
    background-color: $yellow;
    opacity: .5;

    &:hover {
      background-color: $yellow;
    }
  }
}