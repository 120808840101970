* {
    box-sizing: border-box;
}

html,
body,
p,
blockquote,
figure,
ol,
ul {
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
}

strong {
    font-weight: bold;
}

button {
    overflow: visible;
    border: 0;
    padding: 0;
    font: inherit;
    -webkit-font-smoothing: inherit;
    letter-spacing: inherit;
    background: none;
    cursor: pointer;
    text-align: left;
}

svg {
    display: inline-block !important;
}

h1,h2,h3,h4,h5,h6{
    margin: 0;
}

:focus {
    outline: 0;
}