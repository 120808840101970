/**
 * saved wrapper element
 */
.saved {
  display: inline-block;

  .btn--primary {
    width: 40px;
    height: 40px;
    padding: 10px;
    @include breakpoint($breakpoint) {
      width: auto;
    }
  }

  .btn--primary__content {
    display: none;

    @include breakpoint($breakpoint) {
      display: inline-block;
    }
  }
}

.saved__btn {
  position: relative;

  &:before {
    content: '';
    width: 12px;
    height: 12px;
    background-color: white;
    position: absolute;
    top: -28px;
    transform: rotate(45deg);
    border: 1px solid $border-grey2;
    box-shadow: 0 0 2px 0 rgba(black, .15);
    z-index: 1;
    left: 0;
    right: 0;
    margin: auto;
    display: none;

    @include breakpoint($breakpoint) {
      top: auto;
      bottom: -28px;
    }
  }
}


/**
 * saved checked state functionality
 */

.saved__toggle-input {
  display: none;
  opacity: 0;
  position: absolute;
  right: 0;
  top: -9999px;
  z-index: 0;

  &:focus ~ .saved__list {
    .saved__list__item {
      &[for='saved-toggle-desktop'],
      &[for='saved-toggle-mobile'] {
        outline: $focus-outline;
        position: relative;
        z-index: 2;
      }
    }
  }
}


/**
 * saved list styling
 */

.saved__list {
  display: none;
  position: absolute;
  width: 240px;
  font-size: $text-s;
  border: 1px solid $border-grey2;
  box-shadow: 0 0 2px 0 rgba(black, .15);
  margin-top: 20px;
  margin-bottom: 20px;
  bottom: 100%;
  left: 0;

  @include breakpoint($breakpoint) {
    bottom: auto;
    left: auto;
    right: 0;

    &:before {
      top: -6px;
      right: 30px;
      bottom: auto;
      left: auto;
    }
  }
}


/**
 * saved list item styling
 */

.saved__list__item {
  display: block;
  padding: 12px;
  padding-right: 6px;
  background: white;
  z-index: 1;
  position: relative;
  width: 100%;
  cursor: pointer;
  user-select: none;
  transition: background-color $timing, border-color $timing;

  &:hover {
    background-color: $white-hover;

    .saved__list__item__toggle {
      background-color: $bg-grey3;
    }
  }

  &:first-child {
    border-bottom: 1px solid $border-grey2;
  }

  &:focus {
    outline: $focus-outline;
  }
}

.saved__list__item__icon {
  float: right;
  vertical-align: middle;
  margin: 0 5px;
  height: 14px;
  width: 10px;
}


/**
 * saved list toggle styling
 */

.saved__list__item__toggle {
  display: inline-block;
  height: 24px;
  width: 40px;
  border-radius: 14px;
  background-color: $bg-grey1;
  padding: 2px;
  position: relative;
  cursor: pointer;
  float: right;
  top: -4px;
  transition: background-color $timing;
}

.saved__list__item__toggle__switch {
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  transition: transform .2s ease;
  box-shadow: 0 1px 2px 0 rgba(black, .3);
}

// Invisible in bulkdownload
.bulkdownload-map-type {
  .saved {
    display: none;
  }
}