@mixin badge {
  width: 20px;
  height: 20px;
  background: $yellow;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .3);
  text-align: center;
  font-size: $text-l;
  font-weight: bold;
  border-radius: 100%;
  line-height: 22px;
  color: $text;
}