@mixin h1($color: $title-blue) {
  font-size: 18px;
  font-weight: bold;
  color: $color;

  @include breakpoint($breakpoint) {
    font-size: 24px;
  }
}

@mixin h2($color: $title-blue) {
  font-size: 17px;
  font-weight: bold;
  color: $color;

  @include breakpoint($breakpoint) {
    font-size: 21px;
  }
}

@mixin h3($color: $title-blue) {
  font-size: $text-xl;
  font-weight: bold;
  color: $color;

  @include breakpoint($breakpoint) {
    font-size: 18px;
  }
}

@mixin h4($color: $title-blue) {
  font-size: $text-l;
  font-weight: bold;
  color: $color;
}

@mixin h5($color: $title-blue) {
  font-size: $text-s;
  font-weight: bold;
  color: $color;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 1em;
  font-weight: normal;
}