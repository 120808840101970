/**
 * Close button
 */

.btn--close {
  svg {
    transition: fill $timing;
    fill: $blue;
    width: 20px;
    height: 20px;
  }

  &:focus {
    outline: $focus-outline;
  }

  &:hover {
    svg {
      fill: $blue-hover;
    }
  }
}