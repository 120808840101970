@mixin marker-styling {
  display: inline-block;
  border-radius: 100%;
  font-size: $text-xs;
  line-height: 1;
  user-select: none;
  z-index: 0;
}

.ol-overlaycontainer-stopevent {
  display: none;
}

canvas.ol-unselectable {
  z-index: -999999;
  position: relative;
}


/**
 * default markers (different sizes)
 */

@mixin marker--s {
  @include marker-styling;
  width: 6px;
  height: 6px;
  box-shadow: 0 0 2px 0 rgba(black, .9);
}

.marker--s {
  @include marker--s;
}


@mixin marker--m {
  @include marker-styling;
  width: 12px;
  height: 12px;
  border: 1px solid white;
  box-shadow: 0 0 2px 0 rgba(black, .9);
}

.marker--m {
  @include marker--m;
}

@mixin marker--l {
  @include marker-styling;
  min-width: 35px;
  text-align: center;
  padding: 5px 10px;
  border: 1px solid white;
  border-radius: 12px;
  box-shadow: 0 0 3px 0 rgba(black, .9);
}

.marker--l {
  @include marker--l;
}


.marker__label {
  display: inline-block;
  vertical-align: middle;
  pointer-events: none;
  white-space: nowrap;
}

.marker__icon {
  float: right;
  margin-left: 5px;
  width: 12px;
  height: 12px;
  fill: rgba(white, .7);
}


/**
 * marker block
 */

.marker--block {
  display: table;
  width: 15px;
  height: 12px;
  border: 1px solid white;
  box-shadow: 0 0 2px 0 rgba(black, .9);
}

.marker--block--l {
  width: 25px;
  height: 20px;
}

.marker--block__state {
  display: table-cell;
}



/**
 * marker arrow
 */

.marker--arrow {
  position: relative;
  z-index: 0;

  &:hover {
    z-index: 5;
  }
}

.marker--arrow__icon {
  position: relative;
}

.marker--arrow--m {

  .marker--arrow__label {
    font-size: $text-s;
    font-weight: bold;
    position: absolute;
    left: 0;
    z-index: 1;
    padding-top: 23px;
    width: 100%;
    text-align: center;
    height: 100%;
    vertical-align: middle;
    display: inline-block;
    pointer-events: none;
  }
}


/**
 * Theme markers
 */

.marker--theme {
  @include marker--l;
  display:inline-block;
}

.pre-marker {
  @include marker--l;
  min-width: 0;
  display:inline-block;
  position:absolute;
  left: -28px;

  svg {
    padding: 0;
    margin: -2px -6px;
    width: 16px;
    height: 16px;
  }
}

.marker--list {
  .marker--list__item {
    margin-bottom: 2px;

    &.is-wind {
      margin: 20px 0;
      .marker--theme {
        background: transparent !important;
        position: relative;
        overflow: hidden;
        padding: 11.5px 23px;
        border-left: 0;
        left: 15px;
        top: 2px;
        border-radius: 0 12px 12px 0;
        box-shadow: 3px 1px 3px -2px rgba(0, 0, 0, .9);

        &:before {
          content: '';
          position: absolute;
          left: -48px;
          top: -15px;
          background: 0 0;
          width: 50px;
          height: 50px;
          border: 1px solid #fff;
          -webkit-border-radius: 49%;
          border-radius: 49%;
          -webkit-box-shadow: 0 0 0 100em;
          box-shadow: 0 0 0 100em;
        }

        .marker__label {
          z-index: 9999;
          color: #fff;
          position: absolute;
          top: 6px;
          right: 10px;
        }
      }
      .marker--arrow__label {
        padding-top: 9px;
      }
    }

    .marker--arrow__label {
      font-size: 13px;
      font-weight: 700;
      position: absolute;
      left: -24px;
      z-index: 1;
      padding-top: 6px;
      text-align: center;
      height: 100%;
      vertical-align: middle;
      display: inline-block;
      pointer-events: none;
    }
    .marker--arrow__icon {
      position: absolute;
      left: -45px;
      bottom: -20px;
    }
  }
}