@font-face {
  font-family: "RijksoverheidSans";
  src: url("./assets/fonts/ROsanswebtextregular.eot?") format("embedded-opentype"),
    url("./assets/fonts/ROsanswebtextregular.woff") format("opentype");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "RijksoverheidSans";
  src: url("./assets/fonts/ROsanswebtextitalic.eot?") format("embedded-opentype"),
    url("./assets/fonts/ROsanswebtextitalic.woff") format("opentype");
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: "RijksoverheidSans";
  src: url("./assets/fonts/ROsanswebtextbold.eot?") format("embedded-opentype"),
    url("./assets/fonts/ROsanswebtextbold.woff") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "RijksoverheidSerif";
  src: url("./assets/fonts/ROserifwebregular.eot?") format("embedded-opentype"),
    url("./assets/fonts/ROserifwebregular.woff") format("opentype");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "RijksoverheidSerif";
  src: url("./assets/fonts/ROserifwebitalic.eot?") format("embedded-opentype"),
    url("./assets/fonts/ROserifwebitalic.woff") format("opentype");
  font-style: italic;
  font-weight: normal;
}
@font-face {
  font-family: "RijksoverheidSerif";
  src: url("./assets/fonts/ROserifwebbold.eot?") format("embedded-opentype"),
    url("./assets/fonts/ROserifwebbold.woff") format("opentype");
  font-weight: bold;
  font-style: normal;
}



.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  height:60px;
  width:60px;
  margin:0px auto;
  -webkit-animation: rotation .6s infinite linear;
  -moz-animation: rotation .6s infinite linear;
  -o-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
  border-left:6px solid rgba(0,174,239,.15);
  border-right:6px solid rgba(0,174,239,.15);
  border-bottom:6px solid rgba(0,174,239,.15);
  border-top:6px solid rgba(0,174,239,.8);
  border-radius:100%;
}

@-webkit-keyframes rotation {
  from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
  from {-moz-transform: rotate(0deg);}
  to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
  from {-o-transform: rotate(0deg);}
  to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}

@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }

.loader {
  width: 250px;
  height: 100px;
  position: fixed;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 24px;   
  color: #154273;
}