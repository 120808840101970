// content only for screenreaders
@mixin accessibility {
  position: absolute;
  left: -999em;
  top: -100em;
  color: white;
  background-color: black;
  font-weight: normal;
}

@mixin accessibility-focus {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  opacity: 1;
  display: inline-block;
  padding: 1em;
  font-size: 1em;
  line-height: 1;
  background: $blue-hover;
  color: white;
}

.accessibility {
  @include accessibility;
}