/**
 * location list
 */

.location--navigation {
  overflow: visible;
  position: fixed;
  background: white;
  top: 50px;
  z-index: 10;

  height: 60px;
  width: 100%;
  clear: both;
  padding: 10px 20px;
  border-bottom: 1px solid $bg-grey1;

  @include breakpoint(max $breakpoint) {
    top: 110px;
    right: 17px;
    height: calc(100% - 80px);
    width: 30px;
    padding: 18px 0;
  }

  a {
    padding: 13px 6px;
    display: inline-block;
    font-weight: bold;
    font-size: $text-l;
    @include breakpoint(max $breakpoint) {
      padding: 2px 10px;
      font-size: $text-m;
    }
  }

  .location--navigation__search {
    max-width: 255px;
    width: 100%;
    float: right;

    @include breakpoint(max $breakpoint) {
      max-width: 100%;
      width: 100%;
      float: left;
      position: fixed;
      top: 59px;
      height: 47px;
      left: 0;
      margin: 0;
      padding: 0 20px;
      box-shadow: 0 2px 9px -4px;
     }
  }
}

.location--sections {
  @include clearfix;
}

section.location--block {
  position: relative;
  width: 33%;
  padding-left: 20px;
  margin-top: 20px;
  float: left;

  &.is-hidden {
    display: none;
  }

  @include breakpoint(max $breakpoint) {
    width: calc(100% - 40px);
  }

  &:nth-child(3n+1) {
    clear: left;
  }
}

.location--block__header {
  border: 1px solid $border-grey2;
  border-radius: 3px 3px 0 0;
  background-color: $bg-grey2;
  color: $text-grey2;
  font-weight: bold;
  font-size: $text-l;
  display: block;
  width: 100%;
  padding: 10px;

  &.highlight {
    background-color: $bg-blue-2;
    color: $white-hover;
    border-color: $dark-blue;
  }
}

.location--block__list__item {
  position: relative;
  width: 100%;
  user-select: none;

  &.is-inactive {
    opacity: .5;
    pointer-events: none;
  }

  &.is-hidden {
    display: none;
  }
}

.list--location__checkbox {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 10px;
  z-index: 9;

  &:checked + .list--location__label {
    border-color: $blue;
    z-index: 1;
  }

  &:focus {
    outline: $focus-outline;
  }
}

.list--location__label {
  display: block;
  width: 100%;
  position: relative;
  border: 1px solid $border-grey2;
  padding: 15px 10px;
  padding-left: 40px;
  margin-top: -1px;
  cursor: pointer;
  transition: background-color $timing, border-color $timing;

  &:hover {
    background-color: $white-hover;
  }
}

.location__searchbar__clear {
  @include clearfix;
  padding-left: 20px;
  margin-top: 50px;
  display: block;

  span {
    color: $link-color;
    display: inline-block;
    padding-left: 7px;
    top: -1px;
    position: relative;
  }

  svg {
    fill: $link-color;
  }
}

.location--count {
  font-weight: bold;
  color: $text-grey;
  padding: 14px 20px;
  float: right;
}