$red: #d52b1e;
$darkred: #a81120;
$orange: #e17000;
$yellow: #f9e11e;
$green: #39870c;
$brown: #673327;
$blue: #007bc7;

$blue-hover: darken($blue, 10%);
$yellow-hover: #eed60f;

$white-hover: #f3f3f3;
$dark-yellow: #dcc823;
$light-yellow: #fdf6bb;
$dark-blue: #01689b;

$text: #222;
$text-grey: #696969;
$text-grey2: #666;
$text-grey3: #999;
$title-blue: #154273;

// border colors
$border-grey: #e6e6e6;
$border-grey2: #ccc;
$border-grey3: #b4b4b4;

// background colors
$bg-grey1: #e6e6e6;
$bg-grey2: #f3f3f3;
$bg-grey3: #cbcbcb;
$bg-grey4: #ccc;
$bg-grey5: #f7f6f4;
$bg-grey6: #666;


$bg-blue1: #154273;
$bg-blue-2: #007bc7;

$star-active: #ffb612;


// link color
$link-color: #0b76b2;


$sans-serif: rijksoverheidsans;
$serif: rijksoverheidserif;

// font sizes
$text-xxxs: 10px;
$text-xxs: 11px;
$text-xs: 12px;
$text-s: 13px;
$text-m: 14px;
$text-l: 15px;
$text-xl: 16px;
$text-xxl: 21px;

// breakpoint
$breakpoint: 768px;
$breakpoint-xxs: 361px;

// misc
$focus-outline: thin dotted black;

// transition
$timing: .15s;
