/**
 * The input area
 */

.search__query {
  position: relative;
  z-index: 100; // tmp

  @include breakpoint(min $breakpoint) {
    max-width: 320px;
  }

  /**
   * Different view states
   */
   
  /**
   * user is typing
   */
  
  &.is-typing {

    // hide the location icon
    .search__input__location {
      width: 0;
      padding: 0;
    }
  }


  /**
   * there is input
   */
  
  &.is-typing,
  &.has-input {

    // show the reset button
    .search__input__clear {
      width: 12px;
    }
  }

  &.has-results {
    .search__input__button {
      pointer-events: auto;

      > svg {
        opacity: 1;
      }
    }
  }


  /**
   * Show results
   */
  
  &.is-showing-results .search__query__results {
    display: block;
  }


  /**
   * User location
   */
  
  &.has-user-location {
    .search__input__location svg {
      fill: $blue;
    }

    .search__input__field {
      color: $blue;
    }

    .search__query__results__item--user-location {
      color: $blue;
      background-color: white;

      svg {
        fill: $blue;
      }
    }
  }
}



/**
 * Geolocation not supported
 */

.hasnt-geolocation-support {
  .search__query__results__item--user-location {
    display: none;
  }

  .search__input__location {
    width: 0;
    padding: 0;
  }
}


/**
 * The input wrapper
 */

.search__input {
  border: 1px solid $border-grey3;
  border-right-color: $blue;
  box-shadow: inset 1px 1px 3px 0 rgba(103, 103, 103, .1);
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  position: relative;
  overflow: hidden;
}


/**
 * location icon
 */

.search__input__location {
  position: relative;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  transition: width $timing ease-out, padding $timing ease-out;
  padding: 0 10px;

  &:after {
    content: '';
    display: block;
    width: 1px;
    height: 26px;
    background-color: $border-grey2;
    position: absolute;
    right: 0;
    top: 6px;
  }

  &:focus svg {
    outline: $focus-outline;
  }

  &:hover svg {
    fill: $text;
  }

  svg {
    transition: fill $timing;
    vertical-align: middle;
    margin-top: -2px;
    width: 16px;
    height: 16px;
    fill: $text-grey3;
  }
}


/**
 * the input field itself
 */

.search__input__field {
  border: 0;
  padding: 8px;
  font-size: $text-m;
  width: 85%;
  display: inline-block;
}


.search__input__location + .search__input__field {
  width: 70%;
}


/**
 * Reset button
 */

.search__input__clear {
  position: absolute;
  right: 50px;
  width: 0;
  transition: width $timing ease-out;
  overflow: hidden;

  &:focus svg {
    outline: $focus-outline;
  }

  &:hover svg {
    fill: $text;
  }

  svg {
    background-color: white;
    transition: fill $timing;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    fill: $text-grey3;
  }

}


/**
 * Submit button
 */

.search__input__button {
  background-color: $blue;
  padding: 0 11px 0 13px;
  position: absolute;
  right: 0;
  top: -1px;
  bottom: -1px;
  pointer-events: none;
  display: table-cell;

  &:focus {
    outline: $focus-outline;
  }

  &:focus,
  &:hover {
    background-color: $blue-hover;
  }

  > svg {
    vertical-align: middle;
    width: 16px;
    height: 16px;
    opacity: .5;
    fill: white;
    transition: opacity $timing, background-color $timing;
  }
}


/**
 * The results
 */

.search__query__results {
  display: none;
  position: absolute;
  top: 125%;
  left: 0;
  right: 0;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .4);
  font-size: $text-s;
  user-select: none;

  @include breakpoint(min $breakpoint) {
    top: 100%;
  }
}

.search__query__results__item {
  @include clearfix;
  position: relative;
  border-bottom: 1px solid $border-grey3;
  transition: background-color $timing;
  background-color: white;
  cursor: default;

  &:focus {
    outline: $focus-outline;
  }

  &:focus,
  &:hover,
  &.is-active {
    background-color: $bg-grey2;
  }
}

.search__query__results__item__icon {
  display: table-cell;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 11px 7px;
  vertical-align: middle;
  fill: $text-grey;
  float: left;

  svg {
    vertical-align: middle;
  }
}

.search__query__results__item__label {
  vertical-align: middle;
  padding-top: 2px;
  width: calc(100% - 40px - 75px);
  float: left;
  padding: 12px 0;
}

.search__query__results__item__details {
  text-align: right;
  width: 75px;
  color: $text-grey;
  vertical-align: middle;
  float: right;
  padding: 12px 7px;
}


/**
 * The user location link
 */

.search__query__results__item--user-location {
  border: 0;

  &:hover {
    text-decoration: underline;
  }
}


/**
 * No results
 */

.search__query__results__item--no-results,
.search__query__results__item--is-loading {
  font-weight: bold;
  padding-left: 12px;
  padding-right: 12px;

  &:focus,
  &:hover {
    background-color: transparent;
  }

  .search__query__results__item__label {
    width: 100%;
  }
}

.search__query__results__item--is-loading {
  @include spinner;
  overflow: hidden;
  position: relative;

  &:after {
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);
    opacity: 1;
  }
}