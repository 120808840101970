// Vars
@import 'vars';
@import 'ie';
// Libs
@import 'libs/reset';
@import 'libs/normalize';
@import 'libs/breakpoint';
@import 'libs/clearfix';
@import 'libs/tooltips';
// Common
@import 'common/accessibility';
@import 'common/body';
@import 'common/hr';
@import 'common/images';
@import 'common/spinner';
// Typography
@import 'typography/link';
@import 'typography/title';
@import 'typography/body';
@import 'typography/attention-block';
// Components
// markers
@import 'components/markers/markers';
@import 'components/markers/legend';
// charts
@import 'components/charts/legend';
@import 'components/charts/chart';
@import 'components/charts/table';
@import 'components/charts/actions';
@import 'components/charts/related';
@import 'components/charts/error';
@import 'components/charts/share';
// tabs
@import 'components/tabs/primary';
@import 'components/tabs/secondary';
// forms
@import 'components/forms/select';
@import 'components/forms/input';
@import 'components/forms/checkbox';
// maptools
@import 'components/maptools/zoom';
@import 'components/maptools/quick-info';
@import 'components/maptools/saved';
@import 'components/maptools/refresh';
@import 'components/maptools/map-share';
@import 'components/maptools/map-forecast';
// badges
@import 'components/badges/badges';
// buttons
@import 'components/buttons/primary';
@import 'components/buttons/secondary';
@import 'components/buttons/tertiary';
@import 'components/buttons/close';
// nav
@import 'components/nav/nav';
@import 'components/nav/expert';
@import 'components/nav/index';
@import 'components/nav/topbar';
@import 'components/nav/content';
@import 'components/nav/searchbar';
// search
@import 'components/search/select';
@import 'components/search/query';
@import 'components/search/header';
@import 'components/search/related';
// lists
@import 'components/lists/bordered';
@import 'components/lists/public';
@import 'components/lists/parameters';
@import 'components/lists/locations';
// panels
@import 'components/panel/topbar';
@import 'components/panel/checkout';
// app
@import 'components/app/error';
@import 'components/app/status';
@import 'components/app/component';
@import 'components/app/ie-message';
@import 'components/app/icon-sprite';
// snippets
@import 'snippets/details';
@import 'snippets/search';
@import 'snippets/ui';
// print
@import 'print';

.js-page {
  cursor: pointer;
}

.full--page {
  max-width: 1080px;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
}

.loading {
  @include spinner;
  display: table-cell;
  padding-right: 10px;
  position: relative;

  @include breakpoint(max $breakpoint) {
    font-size: 12px;
  }

  &::after {
    margin-left: 10px;
    transition: opacity .15s;
    opacity: 1;
    display: inline-block;
    overflow: hidden;
    background-size: 30px 30px;
    -webkit-transition: opacity .15s;
    width: 30px;
    height: 30px;
    position: absolute;
    top: -3px;
  }
}
