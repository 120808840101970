/**
 * nav index head
 */
.nav__head {
  margin-bottom: 45px;
  position: relative;
}

.nav__head__logo {
  width: 250px;
  margin: 0 auto;
  display: block;
}

.nav__head__button {
  margin: 10px;
  top: 0;
  right: 0;
  position: absolute;

  svg {
    width: 20px;
    height: 20px;
  }
}


/**
 * nav index button
 */
.nav__button {
  margin-bottom: 20px;
}


/**
 * nav index foot
 */
.nav__foot {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 10px 15px;
  background-color: $bg-grey5;
  box-shadow: 0 -1px 2px 0 rgba(black, .15);

  @media screen and (max-height: 560px) {
    position: relative;
  }

  &:before {
    content: '';
    position: absolute;
    display: inline-block;
    width: 44px;
    height: 26px;
    background-color: $bg-blue1;
    bottom: 100%;
    left: 0;
    right: 0;
    margin: auto;
    box-shadow: inset 0 -2px 2px 0 rgba(black, .15);
  }
}

.nav__foot__item {
  display: inline-block;
    margin-right: 20px;
}

.nav__foot__item__link {
    color: $text-grey2;
    text-decoration: underline;

    &:hover {
      color: $text;

      svg {
        fill: $text-grey2;
      }
    }

  svg {
    vertical-align: sub;
    margin-left: 3px;
    text-decoration: none;
    width: 16px;
    fill: $text-grey3;
    transition: fill $timing;
  }
}