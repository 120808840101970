/**
 * quick info map element
 */

.quick-info {
  width: 180px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .4);
  position: relative;
  background-color: white;
  z-index:10000099;
  &:after {
    content: '';
    position: absolute;
    box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .4);
    background-color: white;
    width: 16px;
    display: inline-block;
    transform: rotate(45deg);
    height: 16px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -6px;
  }
}


/**
 * quick info element header
 */

.quick-info__head {
  background-color: $bg-grey1;
  padding: 10px;
  padding-bottom: 5px;
  box-shadow: 0 1px 2px 0 rgba(black, .15);
}


.quick-info__head__label {
  color: $title-blue;
  font-size: $text-m;
  width: 80%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quick-info__head__icon {
  float: right;

  .btn--close__icon {
    width: 17px;
    height: 16px;
  }
}


/**
 * quick info list styling
 */

.quick-info__list__item-wrapper {
  width: 100%;

  &.is-invalid {
    display: none;
  }

  &:focus {
    .quick-info__list__item {
      outline: $focus-outline;
    }
  }
}

.quick-info__list__item {
  width: 100%;
  display: table;
  padding: 8px;
  padding-bottom: 2px;
  border-bottom: 1px solid $border-grey;
}

.quick-info__list__item__type {
  display: table-cell;
  text-align: left;
  vertical-align: middle;
  padding-right: 10px;
}

.quick-info__list__item__type__indicator {
  width: 10px;
  height: 10px;
  display: inline-block;
}

.quick-info__list__item__data {
  display: table-cell;
  width: 100%;
  text-align: left;
}

.quick-info__list__item__data__label {
  font-size: $text-s;
  font-weight: bold;
}

.quick-info__list__item__data__datetime {
  font-size: 11px;
}

.quick-info__list__item__save {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  fill: $bg-grey3;
  width: 14px;
  height: 14px;
  position: relative;
}

/**
 * quick info element footer
 */

.quick-info__foot {
  padding: 5px;
  position: relative;
  z-index: 1;
  background-color: #fff;
}

.quick-info__foot__btn {
  display: block;
  width: 100%;
  border-radius: 3px;
  text-align: center;
  padding: 5px;
  font-weight: bold;
  font-size: 12px;
  background-color: $bg-blue-2;
  color: white;

  &:hover {
    background-color: $blue-hover;
  }

  &:focus {
    outline: $focus-outline;
    background-color: $blue-hover;
  }
}


// Add this class to .quick-info__list__item to make current star yellow
.is-saved {
  .quick-info__list__item__save {
    fill: $star-active;
  }
}