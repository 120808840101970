@mixin link {
  color: $dark-blue;
  text-decoration: none;
  cursor: pointer;
  transition: background-color $timing, color $timing;

  &:hover {
    text-decoration: underline;
    color: $blue-hover;
  }

  &:focus {
    outline: $focus-outline;
  }
}

a {
  @include link;
}

p a {
  text-decoration: underline;
}

.link {
  @include link;
}

.link--icon {
  display: inline-block;
  color: $blue;

  .link--icon__icon {
    margin-right: 6px;
    vertical-align: middle;
    display: inline-block;
    fill: $blue;
  }

  &:hover {
    .link--icon__icon {
      fill: $blue-hover;
    }
  }

  &:focus {
    outline: $focus-outline;
  }
}

/**
 * icon sizes
 */
.link--icon__icon--star {
  width: 14px;
  height: 13px;
}

.link--icon__icon--external {
  width: 18px;
  height: 16px;
}

.link--icon__icon--return {
  width: 16px;
  height: 15px;
}

.link--icon__icon--draw {
  width: 16px;
  height: 16px;
}

.link--icon__icon--circled-chevron-left,
.link--icon__icon--circled-chevron-right {
  width: 20px;
  height: 21px;
}

.link--icon__icon--chevron-left {
  width: 10px;
  height: 16px;
}

.link--icon__icon--groups {
  width: 12px;
  height: 10px;
}

.link--icon__icon--search {
  width: 13px;
  height: 12px;
}

.link--icon__icon--config {
  width: 16px;
  height: 16px;
}

.link--icon__icon--info {
  width: 16px;
  height: 16px;
}