/**
 * List of relevant measurements
 */

.chart-related {
  margin-bottom: 50px;

  @include breakpoint(min $breakpoint) {
    max-width: 50%;
  }
}

.chart-related__title {
  @include h3;
  color: $title-blue;
  margin-bottom: 15px;
}

.chart-related__list {
  border-top: 1px solid $border-grey2;
}

.chart-related__list__item {
  border-bottom: 1px solid $border-grey2;
}

.chart-related__list__item__link {
  display: table;
  width: 100%;
  color: $text;
  padding: 15px 0;
  position: relative;
  transition: background-color $timing;

  &:hover {
    background-color: $bg-grey2;
    color: inherit;

    .chart-related__list__item__body__label {
      text-decoration: underline;
    }

    .chart-related__list__item__icon {
      svg {
        fill: $text;
      }
    }
  }
}

/**
 * The body with icon, link, label etc.
 */

.chart-related__list__item__body {
  display: table-cell;
  vertical-align: top;
  padding: 0 10px;
}

.chart-related__list__item__body__label {
  font-weight: normal;
  display: inline-block;
  margin: 0 3px 0 0;
  padding-right: 3px;

  @include breakpoint($breakpoint) {
    font-size: $text-xl;
  }
}

.chart-related__list__item__body__data {
  display: inline-block;
  margin-bottom: 3px;
  font-weight: bold;

  @include breakpoint($breakpoint) {
    font-size: $text-xl;
  }
}

.chart-related__list__item__body__date {
  font-size: $text-xs;
  display: block;
}

.chart-related__list__item__icon {
  display: table-cell;
  vertical-align: middle;
  width: 12px;

  svg {
    fill: $text-grey2;
    transition: fill $timing;
    width: 12px;
    height: 12px;
  }
}

.chart-related__list__item__illustration {
  display: table-cell;
  vertical-align: middle;
  width: 35px;
  color: $blue;

  svg {
    fill: $blue;
    width: 30px;
    height: 30px;
  }
}