/**
 * Chart Actions
 */

.chart-actions__item {
  display: inline-block;
  margin-right: 10px;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  //&:hover .chart-actions__dropdown {
  //  display: block;
  //}
}

.chart-actions__item__link {
  @include link;
  color: $text;
  font-size: $text-m;
  &.is-auto{
    background: #007bc7;
    color: #f3f3f3;
    padding: 5px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
  }
}

.is-auto .chart-actions__item__link__icon{
  fill: #f3f3f3;
}

.chart-actions__item__link__icon {
  display: inline-block;
  margin-right: 4px;
  margin-top: -2px;
  width: 12px;
  height: 12px;
  vertical-align: middle;
  fill: $text;
}

/**
 * Specific actions
 */

.chart-actions__save {
  &.is-saved {
    .chart-actions__item__link {
      color: $text-grey;
    }

    .chart-actions__item__link__icon {
      fill: $star-active;
    }
  }
}

/**
 * Export
 */

.chart-actions__export {
  margin-right: 0;

  .chart-actions__item__link {
    &.is-active {
      color: $text-grey;
    }
  }

  &:hover {
    .chart-actions__item__link {
      text-decoration: underline;
    }

    //.chart-actions__dropdown {
    //  display: block;
    //}
  }
}

/**
 * Dropdown
 */

.chart-actions__dropdown {
  position: absolute;
  right: 0;
  top: 200%;
  width: 140px;
  border: 1px solid $border-grey2;
  font-size: $text-s;
  z-index: 500;
  display: none;

  &:before {
    border-left: 1px solid $border-grey2;
    border-top: 1px solid $border-grey2;
    background-color: white;
    transform: rotate(45deg);
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    left: 50%;
    width: 10px;
    height: 10px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: -20px;
    right: -20px;
    top: -20px;
    bottom: -20px;
    background: transparent;
    z-index: 0;
  }

  &.is-open {
    display: block;
  }
}

.chart-actions__refresh {
  .chart-actions__dropdown {
    left: 0;
    top: 34px;
    width: 215px;
    &:before {
      left: 15%;
    }
  }
}

.chart-actions__dropdown__item {
  border-bottom: 1px solid $border-grey2;
  z-index: 1;
  position: relative;
  text-align: left;

  &:last-of-type {
    border: 0;
  }
}

.chart-actions__dropdown__item__link {
  @include link;
  display: block;
  width: 100%;
  padding: 10px;
  color: $text;
  background-color: white;
}

.chart-actions__dropdown__item__link.is-active {
  background-color: $bg-grey2;
}

.chart-actions__dropdown__item__link__icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.ui__details__body__item[data-datetimescope] .js-details__actions__expert__button {
  background: $bg-blue-2;
  color: $white-hover;
  padding: 5px;
  border-radius: 2px;

  svg {
    fill: $white-hover;
  }
}
