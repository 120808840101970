@mixin spinner {
  min-height: 20px;

  &:after {
    display: block;
    content: '(bezig met laden)';
    text-indent: -999px;
    overflow: hidden;
    width: 20px;
    height: 20px;
    background: url('../../images/spinner.gif') no-repeat top left;
    background-size: 20px 20px;
    opacity: 0;
    transition: opacity $timing;
  }

  &.is-loading {
    &:after {
      opacity: 1;
    }
  }
}