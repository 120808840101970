hr {
  background-color: $bg-grey2;
  border: 0;
  height: 6px;
  margin: 30px 0;
}

hr.thin {
  background-color: $bg-grey2;
  border: 0;
  height: 2px;
  margin: 15px 0;
}