/**
 * The chart table is the textual representation
 * of the chart. 
 * 
 * Some weird stuff going on to make the
 * table headers fixed.
 */


.chart-table-wrapper {
  height: 375px;
  position: relative;
  font-size: $text-xxxs;

  @include breakpoint($breakpoint) {
    height: 426px;
    font-size: $text-xl;
  }
}

.chart-table-wrapper__inner {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 25px; // height of fixed header -1

  @include breakpoint($breakpoint) {
    padding-top: 39px; // height of fixed header -1
  }
}

.chart-table-wrapper__fixed-header {
  height: 26px;
  position: absolute;
  top: -1px; // to obscure overflowing table draw on mobile
  left: 0;
  right: 0;
  background-color: $bg-grey1;

  @include breakpoint($breakpoint) {
    right: 14px;
    height: 40px;
  }
}


/**
 * The table itself
 */

.chart-table {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;

  td {
    padding: 6px 8px;
    text-align: left;
    border-right: 1px solid $border-grey2;
    border-bottom: 1px solid $border-grey2;

    @include breakpoint($breakpoint) {
      padding: 12px;
    }

    &:last-of-type {
      border-right: 0;
    }
  }

  tr {
    &:last-of-type td,
    &:last-of-type th {
      border-bottom: 0;
    }

    th {
      &:nth-of-type(1) {
        width: 75px;

        @include breakpoint($breakpoint) {
          width: 120px;
        }
      }

      &:nth-of-type(2) {
        width: 45px;

        @include breakpoint($breakpoint) {
          width: 80px;
        }
      }

      // four columns
      &:nth-last-child(n+4),
      &:nth-last-child(n+4) ~ th {
        .chart-table__head__item__content {
          /**
           * calculate header width
           * ((table width) - (date column width) - (time column width) - (scrollbar offset)) / (2 columns)
           */

          max-width: calc((100% - 75px - 45px) / 2);

          @include breakpoint($breakpoint) {
            max-width: calc((100% - 120px - 80px - 14px) / 2);
          }
        }
      }

      // five columns
      &:nth-last-child(n+5),
      &:nth-last-child(n+5) ~ th {
        .chart-table__head__item__content {

          /**
           * calculate header width
           * ((table width) - (date column width) - (time column width) - (scrollbar offset)) / (3 columns)
           */

          max-width: calc((100% - 75px - 45px) / 3);

          @include breakpoint($breakpoint) {
            max-width: calc((100% - 120px - 80px - 14px) / 3);
          }
        }
      }
    }
  }
}

.chart-table__head {
  font-weight: bold;
}

.chart-table__head__item {
  line-height: 40px;

  &:first-of-type .chart-table__head__item__content {
    border-left: 0;
  }
}

.chart-table__head__item__content {
  display: block;
  position: absolute;
  top: 0;
  line-height: 14px;
  padding: 6px 8px;
  border-left: 1px solid $border-grey2;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include breakpoint($breakpoint) {
    font-size: $text-m;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  &:hover {
    white-space: inherit;
    background-color: $bg-grey1;
  }
}

.chart-table__body {
  background-color: $bg-grey2;
}