@media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    .custom-select select {
      &::-ms-expand {
        display: none;
      }

      &:focus {
        &::-ms-value {
          background: transparent;
          color: #222;
        }
      }
    }
}