.checkbox-input {
  display: none;

  &:checked ~ .checkbox {
    background-color: $blue;
    transition: background-color $timing;

    .checkbox__switch {
      transform: translateX(80%);
    }
  }
}

.checkbox {
  display: inline-block;
  height: 24px;
  width: 40px;
  border-radius: 14px;
  background-color: $bg-grey1;
  padding: 2px;
  position: relative;
  cursor: pointer;
}

.checkbox__switch {
  display: block;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  transition: transform .2s ease;
  box-shadow: 0 1px 2px 0 rgba(black, .3);
}