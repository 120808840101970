.search__header {
  background-color: $yellow;
  position: relative;
  text-align: center;
  height: 45px;
}

.search__header__title {
  font-size: 17px;
  font-weight: normal;
  line-height: 45px;
}

/**
 * The button icons
 */

@mixin search-header-button {
  width: 44px;
  line-height: 45px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: transparent;
  transition: background-color $timing, border-color $timing;
  cursor: default;
  overflow: hidden;

  &:hover {
    background-color: $light-yellow;

    .search__header__hamburger__icon {
      opacity: 1;
    }
  }

  &:focus {
    outline: $focus-outline;
  }
}

.search__header__hamburger__icon {
  display: block;
  margin: 0 auto;
  width: 16px;
  height: 2px;
  vertical-align: middle;
  background-color: $text;
  position: relative;
  opacity: .8;
  transition: opacity $timing;

  &:before,
  &:after {
    background-color: $text;
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    width: 16px;
    content: '';
  }

  &:before {
    top: -5px;
  }

  &:after {
    bottom: -5px;
  }
}

.search__header__hamburger {
  @include search-header-button;
  border-right: 1px solid $dark-yellow;
  left: 0;

  &:hover {
    border-right-color: $light-yellow;

    > svg {
      opacity: 1;
    }
  }
}

a.search__header__link {
  text-decoration: none;
  color: $text;
}

.search__header__search-button {
  @include search-header-button;
  border-left: 1px solid $dark-yellow;
  right: 0;

  > svg {
    opacity: .8;
    width: 17px;
    height: 17px;
    vertical-align: middle;
    fill: $text;
    display: block !important;
    margin: 0 auto;
  }

  &:hover {
    border-left-color: $light-yellow;
  }

  @include breakpoint(min $breakpoint) {
    display: none;
  }
}

.bulkdownload-map-type .search__header__search-button{
  border-left: 1px solid $border-grey2;
  &:hover {
    background-color: $bg-grey1;
  }
}

.is-checkout .search__header__search-button{
  display: none;
}

.is-drawmode .search__header__search-button{
  display: none;
}

.bulkdownload-map-type .search-wrapper {
  @include breakpoint(max $breakpoint) {
    padding: 0px;
  }
}
.bulkdownload-map-type .is-showing-search .search-wrapper{
  @include breakpoint(max $breakpoint) {
    padding: 10px;
  }
}