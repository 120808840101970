.app-error {
  background: $red;
  color: white;
  padding: 16px 15px 17px 15px;
  font-size: $text-l;
  position: relative;
  z-index: 10;

  @include breakpoint(min $breakpoint) {
    text-align: center;
  }
}

.app-error__link {
  color: white;
  text-decoration: underline;

  &:hover {
    color: white;
    text-decoration: none;
  }
}