/**
 * The share embed / link for charts
 */

.chart-share {
  border: 3px solid $blue;
  border-radius: 3px;
  font-size: $text-s;
  position: relative;
  padding: 15px;
  margin-bottom: 15px;

  &:last-of-type {
    margin-bottom: 0;
  }

  @include breakpoint(min $breakpoint) {
    display: table;
    table-layout: fixed;
    padding: 0;
    width: 100%;
  }
}


/**
 * The label
 */

.chart-share__label {
  vertical-align: middle;
  white-space: nowrap;

  @include breakpoint(min $breakpoint) {
    display: table-cell;
    width: 10%;
    padding: 8px 15px;
  }
}

.chart-share__label__icon {
  display: inline-block;
  margin-right: 4px;
  vertical-align: middle;
  width: 14px;
  height: 11px;
}


/**
 * Input
 */

.chart-share__input {
  padding: 15px 0;

  @include breakpoint(min $breakpoint) {
    display: table-cell;
    width: 60%;
    padding: 8px 0 8px 15px;
  }
}

.chart-share__input__field {
  border: 1px solid $border-grey3;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, .23);
  border-radius: 3px;
  padding: 8px;
  width: calc(100% - 16px);
}


/**
 * Copy button
 */

.chart-share__copy {
  @include breakpoint(min $breakpoint) {
    display: table-cell;
    vertical-align: middle;
  }
}

.chart-share__copy__button {
  @include link;
}


/**
 * Close button
 */

.chart-share__close {
  position: absolute;
  top: 15px;
  right: 15px;

  @include breakpoint(min $breakpoint) {
    display: table-cell;
    vertical-align: middle;
  }
}