
.checkout{
  .checkout-section{
    padding:0 20px;
    background-color: $bg-grey2;
    margin-bottom:20px;
    border-radius: 3px;
    .list-row{
      border-bottom:1px solid $border-grey2;
      &:last-of-type{
        border-bottom:none;
      }

      @include clearfix;
      .col1{
        font-weight:bold;
        width:15%;
        display:block;
        padding:20px 0;
        float:left;
        @include breakpoint(max $breakpoint) {
          width:100%;
        }
      }
      .col2{
        padding:20px 0;
        width:85%;
        display:block;
        float:left;
        &.actioncol{
          width:100%;
          text-align:right;
        }
        &:after{
          @include clearfix;
        }
        @include breakpoint(max $breakpoint) {
          width:100%;
        }
      }
    }

  }
  .checkout--label{
    font-weight:bold;
    display:block;
    padding:15px 0 5px 0;
  }
  .checkout--field{
    padding-left:5px;
    height:47px;
    width: calc(100% - 215px);
    display:inline-block;
    border: 1px solid #b4b4b4;
    -webkit-box-shadow: inset 1px 1px 3px 0 rgba(103,103,103,.1);
    box-shadow: inset 1px 1px 3px 0 rgba(103,103,103,.1);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    &.error{
      border: 1px solid #b82800;
      background: rgba(255, 35, 0,.04);
    }
    @include breakpoint(max $breakpoint) {
      width:100%;
    }
  }
  .submit--checkout{
    font-size: 14px!important;
    width: 200px;
    margin-left:15px;

    display: inline-block;
    @include breakpoint(max $breakpoint) {
      width:100%;
      margin-left:0;
      margin-top:20px;
    }
  }
}

.js-new-download{
  background:$bg-grey1;
  font-weight:bold;
  color:$text;
  padding:8px 10px;
  margin-bottom:20px;
  svg{
    width: 15px;
    height: 15px;
    fill: $text;
    margin-right: 5px;
    top: 3px;
    position: relative;
  }
}

.redbox{
  border: 1px solid #e04228;
  box-shadow: 0 0 1px 0 #b82800 inset;
  border-radius: 3px;
  padding: 15px;
  color:#8e2614;
  margin-bottom:10px;
  margin-top:5px;
  li{
    margin-bottom:5px;

  }
  .link--back{
    display: block;
    margin: 10px 10px 10px 0;
  }
}

.bluebox{
  border: 1px solid #007bc7;
  box-shadow: 0 0 1px 0 #007bc7 inset;
  border-radius: 3px;
  padding: 15px;
  .link--back{
    display: block;
    margin: 10px 10px 10px 0;
  }
}
.parameter__list ul li{
  margin-bottom:3px
}
.link--icon.js-checkoutremoveitem{
  color: #555555;
  font-weight: bold;
  transition: opacity $timing;
  &:hover{
    opacity:0.8
  }
}
.link--icon__icon.link--icon__icon--trash{
  width: 23px;
  height: 25px;
  top: -2px;
  left: 3px;
  position: relative;

}
.nav--download-selection button.btn--secondary.js-expert-to-download{
  margin-left:0;
}