.search-related {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .4);
  background-color: white;
}

/**
 * Header
 */

.search-related__title {
  font-size: $text-s;
  font-weight: bold;
  padding: 9px 10px 7px 10px;
  border-bottom: 1px solid $border-grey3;
  overflow: hidden;
}

.search-related__title__close {
  float: right;

  svg {
    vertical-align: middle;
    width: 20px;
    height: 20px;
  }
}

// add large click area
.search-related__title__close__btn {
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }
}


/**
 * List
 */

.search-related__list__item {
  width: 100%;
  border-bottom: 1px solid $border-grey3;

  &:last-of-type {
    border-bottom: 0;
  }
}

.search-related__list__item__button {
  display: block;
  width: 100%;
  transition: background-color $timing;
  background-color: white;
  cursor: default;
  font-size: $text-s;

  &:hover {
    background-color: $bg-grey2;
  }

  &:focus {
    outline: $focus-outline;
  }
}

.search-related__list__item__button__icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 11px 7px;
  vertical-align: middle;
  fill: $text-grey;
}

.search-related__list__item__button__label {
  display: inline-block;
  width: calc(100% - 40px - 60px);
  vertical-align: middle;
  padding-top: 2px;
}

.search-related__list__item__button__details {
  display: inline-block;
  width: 60px;
  text-align: right;
  color: $text-grey;
  vertical-align: middle;
  padding: 9px 7px 7px 7px;
}
