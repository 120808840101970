input {
  &::placeholder {
    color: $text-grey;
  }

  &::-ms-clear {
    display: none;
  }
}

