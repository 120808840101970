/**
 * parameters list
 */

.list--parameters {
  position: relative;
  margin-bottom: 0;
}

.list--parameters__item {
  position: relative;
  width: 100%;
  user-select: none;
  font-size:14px;

  &.is-inactive {
    opacity: .5;
    pointer-events: none;
  }
}

.list--parameters__checkbox {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  left: 45px;
  z-index: 10;

  &:checked + .list--parameters__label {
    border-color: $blue;
    z-index: 1;
  }

  &:focus {
    outline: $focus-outline;
  }
}

.list--parameters__label {
  display: block;
  width: 100%;
  position: relative;
  border: 1px solid $border-grey2;
  padding: 15px 10px;
  padding-left: 40px;
  margin-top: -1px;
  cursor: pointer;
  transition: background-color $timing, border-color $timing;

  i.synonyms {
    color:$text-grey3;
    display:none;
    width:100%;
  }
  &:hover {
    background-color: $white-hover;
  }
}

.filtered .list--parameters__label i.synonyms{
  display:inline-block;
}