.search {
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .4);
}

.search .search__query {
  transition: opacity $timing .05s ease-out, max-height $timing, margin $timing;
  opacity: 0;
  max-height: 0;
  margin-top: 0;

  @include breakpoint(min $breakpoint) {
    opacity: 1;
    max-height: 100px;
    margin-top: 10px;
  }
}

.search-wrapper {
  padding: 10px;
}


/**
 * Related
 */

.search-related {
  position: relative;
  border-top: 1px solid $border-grey3;

  @include breakpoint(max $breakpoint) {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -2px;
      height: 1px;
      background-color: white;
    }
  }

  @include breakpoint(min $breakpoint) {
    margin: 15px 0 0 0;
    border-top: 0;
  }
}



/* Firefox select alignment bug fix */
@-moz-document url-prefix() {

  .search select {
    padding-top: 9px;
  }
}