/**
 * Secondary tabs
 */

.secondary-tabs {
  @include clearfix;
  display: table;
  width: 100%;
}

/**
 * Secondary tabs item
 */

.secondary-tabs__item {
  display: table-cell;
  border: transparent solid;
  border-width: 1px;
  font-size: $text-s;
  border-radius: 3px 3px 0 0;
  border-bottom-color: $border-grey2;
  transition: background-color $timing, color $timing;

  &:hover {
    background-color: $white-hover;
    text-decoration: none;
    color: $blue-hover;

    .secondary-tabs__item__icon {
      fill: $blue-hover;
    }
  }


  /**
   * Secondary tabs active state
   */

  &.is-active {
    background-color: white;
    color: $text;
    border-color: $border-grey2;
    border-bottom-color: white;

    &:hover {
      cursor: default;
    }

    .secondary-tabs__item__link__icon {
      fill: $blue;
    }
  }
}

/**
 * link
 */

.secondary-tabs__item__link {
  padding: 10px 0;
  width: 100%;
  text-align: center;

  &:focus {
    outline: $focus-outline;
  }
}


/**
 * Secondary tabs icon
 */

.secondary-tabs__item__link__icon {
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;
  width: 14px;
  height: 14px;
  fill: $blue;
}