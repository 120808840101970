/**
 * Main nav styling
 */

.nav {
  width: 100%;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: white;
}

.is-showing-expert-search {
  .nav--map-selection {
    overflow-y: scroll;
  }
}

.pika-single {
  z-index: 9999;
  display: block;
  position: relative;
  color: #333;
  background: #fff;
  border: 1px solid #ccc;
  border-bottom-color: #bbb;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.pika-single:before,
.pika-single:after {
  content: " ";
  display: table;
}
.pika-single:after { clear: both }

.pika-single.is-hidden {
  display: none;
}

.pika-single.is-bound {
  position: absolute;
  box-shadow: 0 5px 15px -5px rgba(0,0,0,.5);
}

.pika-lendar {
  float: left;
  width: 240px;
  margin: 8px;
}

.pika-title {
  position: relative;
  text-align: center;
}

.pika-label {
  display: inline-block;
  *display: inline;
  position: relative;
  z-index: 9999;
  overflow: hidden;
  margin: 0;
  padding: 5px 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  background-color: #fff;
}
.pika-title select {
  cursor: pointer;
  position: absolute;
  z-index: 9998;
  margin: 0;
  left: 0;
  top: 5px;
  filter: alpha(opacity=0);
  opacity: 0;
}

.pika-prev,
.pika-next {
  display: block;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 0;
  padding: 0;
  width: 20px;
  height: 30px;
  /* hide text using text-indent trick, using width value (it's enough) */
  text-indent: 20px;
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 75% 75%;
  opacity: .5;
  *position: absolute;
  *top: 0;
}

.pika-prev:hover,
.pika-next:hover {
  opacity: 1;
}

.pika-prev,
.is-rtl .pika-next {
  float: left;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==');
  *left: 0;
}

.pika-next,
.is-rtl .pika-prev {
  float: right;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=');
  *right: 0;
}

.pika-prev.is-disabled,
.pika-next.is-disabled {
  cursor: default;
  opacity: .2;
}

.pika-select {
  display: inline-block;
  *display: inline;
}

.pika-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}

.pika-table th,
.pika-table td {
  width: 14.285714285714286%;
  padding: 0;
}

.pika-table th {
  color: #000;
  font-size: 12px;
  line-height: 25px;
  font-weight: bold;
  text-align: center;
}

.pika-button {
  cursor: pointer;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  outline: none;
  border: 0;
  margin: 0;
  width: 100%;
  padding: 10px;
  color: #666;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  background: #f5f5f5;
}

.pika-week {
  font-size: 11px;
  color: #999;
}

.is-today .pika-button {
  color: #33aaff;
  font-weight: bold;
}

.is-selected .pika-button {
  color: #fff;
  font-weight: bold;
  background: #33aaff;
  box-shadow: inset 0 1px 3px #178fe5;

}

.is-inrange .pika-button {
  background: #D5E9F7;
}

.is-startrange .pika-button {
  color: #fff;
  background: #6CB31D;
  box-shadow: none;

}

.is-endrange .pika-button {
  color: #fff;
  background: #33aaff;
  box-shadow: none;

}

.is-disabled .pika-button,
.is-outside-current-month .pika-button {
  pointer-events: none;
  cursor: default;
  color: #999;
  opacity: .3;
}

.pika-button:hover {
  color: #fff;
  background: $blue;
  box-shadow: none;

}

/* styling for abbr */
.pika-table abbr {
  border-bottom: none;
  cursor: help;
}
.nav--download-selection .nav__parameters__btn--split{
  button.btn--tertiary.js-page {
    font-size: 14px !important;
    width: 49%;
    display: inline-block;
    margin-right: 1%;
  }
  button.btn--secondary {
    font-size: 14px !important;
    width: 49%;
    margin-left: 1%;
  }
}
.js-nav__bulkdownload-parameters__form__submit{
  font-size: 14px !important;
}
.ui__navigation--bottom .btn--secondary  {
  width: auto;
  float: right;
  margin-right: 0;
  font-size: 14px !important;
  @include breakpoint(max $breakpoint-xxs) {
    font-size: 11px !important;
  }
}

.ui__navigation--bottom .btn--tertiary {
  width: auto;
  float: left;
  margin-right: 6px;
  font-size: 14px !important;
  @include breakpoint(max $breakpoint-xxs) {
    font-size: 11px !important;
  }
}
.ui__navigation--bottom {
  z-index: 999;
  font-size: 14px !important;
}
.nav__download-parameters__list{
  margin-bottom:80px;
}
.nav__parameters__btn--split{
  position: fixed;
  padding: 15px 0;
  bottom: 0;
  width: 313px;
  background: #fff;
  z-index: 999;
  &.relative{
    position:relative;
    .btn--secondary{
      margin-left:0;
    }
  }
}
.list--parameters__item.is-hidden{
  display:none;
}
.nav__content--bulkdownload .nav__expert__searchbar__button:hover svg{
  fill: #696969;
}

input.error{
  border: 1px solid #b82800 !important;
  background: rgba(255, 35, 0,.04);
}

.is-user-expert, .is-user-public {
  .nav__head__button {
    display: none;
    &.is-kaart {
      display: block;
    }
  }
}

.is-user-theme .nav__head__button {
  display: none;
  &.is-thema {
    display: block;
  }
}