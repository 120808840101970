/**
 * Primary tabs
 */

.primary-tabs {
  border: 1px solid $blue;
  font-weight: bold;
  display: table;
  width: 100%;
  border-radius: 3px;
  table-layout: fixed;
}


/**
 * Primary tabs item
 */

.primary-tabs__item {
  display: table-cell;
  text-align: center;
}

.primary-tabs__item__link {
  display: block;
  text-decoration: none;
  padding: 6px 15px;
  line-height: 1;
  color: $blue;
  font-weight: bold;
  width: 100%;
  text-align: center;
  transition: background-color $timing;

  &:hover {
    color: $blue-hover;
    text-decoration: underline;
    cursor: pointer;
  }

  &:focus {
    border: $focus-outline;
    outline-color: white;
    text-decoration: underline;
  }

}

/**
 * Active state
 */

.primary-tabs__item.is-active .primary-tabs__item__link {
  color: white;
  background-color: $blue;

  &:hover {
    text-decoration: none;
    cursor: default;
  }
}