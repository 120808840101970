/**
 * map share styling
 */

.share__link {
  &.is-showing-widget {
      display: block;
  }

  display: none;
  position: fixed;
  width: calc(65vw);
  min-width: 250px;
  font-size: $text-s;
  border: 1px solid $border-grey2;
  box-shadow: 0 0 2px 0 rgba(black, .15);
  margin-top: 20px;
  margin-bottom: 20px;
  bottom: 45px;
  left: 10px;
  background-color: #fff;

  @include breakpoint(min $breakpoint) {
    left: 20px;
  }

  .map-share__input {
    display: flex;
    flex-direction: row;

    .map-share__input__field {
      border: 1px solid #b4b4b4;
      box-shadow: inset 0 1px 3px 0 rgba(0,0,0,.23);
      border-radius: 3px;
      padding: 8px;
      margin: 5px;
      flex-grow: 1;
    }
  
    .map-share__copy__button {
      color: $blue;
      margin: 5px;
      padding: 8px;
      &:focus {
        outline: $focus-outline;
        position: relative;
        z-index: 2;
      }
    }
  }
}