.js-app-status {
    color: white;
    padding: 16px 15px 17px 15px;
    font-size: $text-l;
    position: relative;
    text-align: center;
  
    @include breakpoint(min $breakpoint) {
      text-align: center;
    }
}

.status-error {
  background: $red;
  z-index: 10;
}

.status-warning {
  background: $orange;
  z-index: 10;
}
  
.status-info {
  background: $blue;
  z-index: 9999;
}
  
.app-status__link {
  color: white;
  text-decoration: underline;

  &:hover {
    color: white;
    text-decoration: none;
  }
}

.status-close-btn {
  float: right;

  .btn--close__icon {
    fill: #FFF;
  }

  &:hover {
    svg {
      fill: #000;
      opacity: .5;
    }
  }
}