.component-error {
  color: $red;
}

.component-empty {
  font-style: italic;
}

.details__chart__legend{
  padding-top:30px;
}

