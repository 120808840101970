.highcharts-tooltip {

  > span {
    display: block;
    position: static !important;
    top: auto !important;
    left: auto !important;
    box-shadow: 0 0 2px rgba(0, 0, 0, .15);
    background: white;
    border: 1px solid #ccc;
    white-space: inherit !important;

    span {
      font-family: RijksoverheidSans;
      font-size: 13px;

      &:first-of-type {
        font-size: 12px !important;
      }
    }

    svg {
      vertical-align: middle;
      display: inline-block;
    }
  }
}