@media print {
  body {
    font-family: Arial, Times, serif !important;
  }

  .highcharts-tooltip > span span {
    font-family: Arial, Times, serif !important;
  }
  @page {
    size: A4;
    margin-left: auto;
    margin-right: auto;
  }

  html,
  .ui {
    height: auto;
  }

  .is-showing-saved,
  .is-showing-details {
    .ui__map,
    .ui__tools,
    .ui__actions,
    .ui__search,
    .ui__meta {
      display: none;
    }
  }

  .ui__details,
  .ui__saved {
    min-height: 100%;
    background-color: white;
    z-index: 100;
    width: 100%;
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }

  .chart-table-wrapper__inner,
  .ui__details,
  .ui__saved {
    overflow: visible;
  }

  .chart-table-wrapper {
    height: auto;
  }

  .ui__reset,
  .ui__details__footer,
  .chart-share,
  .chart-related,
  .ui__details__header,
  .details__chart__limits,
  .details__actions__view,
  .details__actions__buttons {
    display: none !important;
  }


  /**
   * Details item
   */
  .ui__details__body {
    padding: 0;
  }

  .ui__details__body__item {
    page-break-inside: avoid !important;
  }

  .details__title {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .details__description {
    margin-bottom: 10px;
    font-size: 11px;
  }

  .details__actions {
    display: block;
    height: auto;
    margin-bottom: 5px;
  }

  .details__actions__range {
    padding: 0;
    width: auto;
    height: auto;

    select {
      height: auto;
      font-size: 11px;
      line-height: 1;
      width: auto;
    }
  }

  .details__info-button {
    display: none;
  }

  /**
   * Table
   */
  .chart-table,
  .chart-table {
    td,
    th {
      padding: 4px;
      font-size: 11px;
    }
  }

  .chart-table__head__item {
    vertical-align: top;
  }

  .chart-table__head__item__content {
    position: relative;
    top: 0;
    white-space: inherit;
    overflow: visible;
    text-overflow: inherit;
    border: 0;
    font-size: 11px;
    line-height: 1;
  }

  .chart-legend__list__item {
    margin-bottom: 0;
  }

  .chart-legend__list__item__label {
    font-size: 11px;
  }


  /**
   * Chart
   */
  .details__chart__chart {
    transform: scale(.75, .75);
    transform-origin: 0 0;
    margin-bottom: -100px;
  }


  /**
   * Print single item action
   */
  .should-not-print {
    display: none;
  }
}
