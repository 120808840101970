html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.ui {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  min-height: 500px;

  .ui__meta__toggledraw,
  .ui__meta__toggleselect,
  .ui__draw-info,
  .ui__locations--list,
  .ui__checkout,
  .ui__download__info {
    display: none;
  }
  /**
   * Loading state
   */

  &.is-loading {
    .ui__meta__loading {
      &:after {
        opacity: 1;
      }
    }
  }

  /**
   * Page has an error
   */

  &.has-error {
    @include breakpoint(min $breakpoint) {
      margin-top: 50px; // magic number: height of .app-error
      height: calc(100% - 50px);
    }

    .ui__error {
      display: block;
    }

    .ui__status {
      display: none;
    }
  }

  /**
   * Is showing navigation
   */

  &.is-showing-nav {
    .ui__reset {
      display: block;
    }

    .ui__nav {
      display: block;
    }
  }

  /**
   * Show details
   */

  &.is-showing-details {
    .ui__reset {
      display: block;
    }

    .ui__details {
      display: block;
    }
  }

  /**
   * Show details
   */

  &.is-showing-saved {
    .ui__reset {
      display: block;
    }

    .ui__saved {
      display: block;
    }
  }

  /**
   * Show map info
   */

  &.is-showing-map-info {
    .ui__reset {
      display: block;
    }

    .ui__map-info {
      display: block;
      padding-bottom: 0;
    }

    .nav__foot {
      &:before {
        display: none;
      }
    }

    .ui__map-info .nav__foot {
      position: relative;
      bottom: 0;
      left: 0;
    }
  }

  /**
   * Show nav info
   */

  &.is-showing-nav-info {
    .ui__reset {
      display: block;
    }

    .ui__nav-info {
      display: block;
      padding-bottom: 0;
    }
  }

  /**
   * Has map info
   */
  
  &.has-map-info {
    .ui__meta__info {
      display: table-cell;
    }
  }

  /**
   * Optional display of components for embedding
   */

  &.embed-no-info {
    .ui__meta__info {
      display: none;
    }
  }
  
  &.embed-no-share {
    .ui__meta__share {
      display: none;
    }
  }

  &.embed-no-refresh {
    .ui__meta__refresh {
      display: none;
    }
  }

  &.embed-no-search {
    .search__query {
      display: none;
    }
  }

  &.embed-no-menu {
    .search__header__hamburger {
      display: none;
    }
  }

  &.embed-no-map {
    .search__select {
      display: none;
    }
    .search__query {
      margin-top: 0;
    }
  }

  &.embed-no-forecast {
    .ui__meta__forecast {
      display: none;
    }
  }

  &.embed-no-search.embed-no-map {
    .search-wrapper {
      padding: 0;
    }
  }

  @include breakpoint($breakpoint) {
    .ui__actions__bulk {
      display: table-cell;
    }
  }

  /**
   * App state: no map type yet
   */

  &.hasnt-map-type {
    .ui__nav .btn--close {
      display: none;
    }

    &.is-showing-nav .ui__reset {
      pointer-events: none;
    }

    .ui__meta__refresh {
      display: none;
    }
    
    @include breakpoint($breakpoint) {
      .ui__actions__bulk {
        display: none;
      }
    }
  }

  /**
   * Bulkdownload mode
   */

  &.is-location-list {
    .js-search__toggle-button {
      display: none;
    }

    .ui__search,
    .ui__tools,
    .ui__map,
    .button__view--list {
      display: none;
    }

    .ui__navigation--top,
    .ui__navigation--bottom,
    .ui__locations--list,
    .button__view--map {
      display: block;
    }

    .ui__locations--list {
      position: relative;
      top: 110px;
      padding-bottom: 10px;
      height: calc(100% - 180px);
      overflow-y: scroll;

    }

    .ui__meta,
    .ui__actions {
      display: none;
    }
  }

  &.bulkdownload-map-type {
    .ui__actions {
      display: none;
    }
  }

  &.bulkdownload-map-type.has-map-info {
    .ui__navigation--bottom,
    .ui__navigation--top,
    .button__view--list {
      display: block;
    }

    .ui__meta__toggleselect,
    .button__view--map,
    .ui__meta__refresh,
    .ui__tools__legend,
    .ui__actions,
    .ui__meta__info,
    .ui__meta__share,
    .ui__meta__forecast {
      display: none;
    }

    .ui__meta,
    .ui__tools {
      bottom: 90px;
    }

    .ui__meta {
      display: block;
    }

    .ui__download__info,
    .ui__meta__loading {
      display: table-cell;
    }

    .ui__search {
      top: 70px;

      .search__header {
        display: none;
      }

      @include breakpoint(max $breakpoint) {
        top: 50px;
      }
    }

    .search__query {
      margin-top: 0;
    }

    .ui__meta__toggledraw {
      display: table-cell;
    }

    &.is-drawmode {
      cursor: url('../../images/drawcursor.png') 2 28, auto;

      .ui__meta__toggledraw,
      .ui__search {
        display: none;
      }

      .ui__meta__toggleselect {
        display: table-cell;
      }

      .ui__draw-info {
        display: block;
        position: fixed;
        top: 70px;
        left: 20px;
        padding: 10px 12px;
        background: rgba(255, 255, 255, .8);
        z-index: 19;
      }
    }
  }

  &.is-checkout {
    .ui__search,
    .ui__tools,
    .ui__map,
    .ui__actions__bulk,
    .button__view--list,
    .ui__meta {
      display: none;
    }

    .ui__navigation--top {
      display: block;
    }

    .ui__checkout {
      position: relative;
      top: 50px;
      padding: 10px 0;
      height: calc(100% - 50px);
      overflow-y: scroll;
      display: block;
    }
  }

  /**
  * Desktop saved
  */

  .ui__actions__saved {
    display: table-cell;

    @include breakpoint(max $breakpoint) {
      display: none;
    }
  }

  &.embed-no-saved {
    .ui__actions__saved {
      display: none;
    }
  }

  .ui__actions__saved,
  .ui__meta__saved {

    /**
    * Expanded state
    */


    .saved__list.is-showing-widget {
      display: block;
    }


    /**
    * Active state
    */

    &.is-active {
      .saved__list .saved__list__item__toggle {
        background-color: $blue;

        .saved__list__item__toggle__switch {
          transform: translateX(80%);
        }
      }

      .saved__btn {
        .btn--primary {
          background-color: $blue;
          color: white;

          &:hover {
            background-color: $blue-hover;
          }

          svg {
            fill: white;
          }
        }
      }
    }
  }
}

/**
 * App error
 */

.ui__error {
  display: none;

  @include breakpoint(min $breakpoint) {
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
  }
}

.ui__status {
  position: relative;
  display: block;
  padding: 0;

  @include breakpoint(min $breakpoint) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}

/**
 * The search box
 */

.ui__search {
  z-index: 10;
  position: relative;

  @include breakpoint(min $breakpoint) {
    position: absolute;
    left: 20px;
    top: 20px;
    width: 340px;
    max-width: 100%;
  }

  /**
   * Different view states
   */

  @include breakpoint(max $breakpoint) {
    &.is-showing-search {
      .search__header__search-button {
        background-color: $light-yellow;
        border-left-color: $light-yellow;

        svg {
          opacity: 1;
        }
      }

      .search__query {
        opacity: 1;
        max-height: 100px;
      }
    }
  }
}

.ui__select {
  display: none;
  z-index: 10;
  position: relative;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.4);
  top: 50px;

  @include breakpoint(min $breakpoint) {
    position: absolute;
    right: 20px;
    top: 50px;
    margin-top: 15px;
    width: 280px;
    max-width: 100%;
  }

  .details__actions__range {
    padding: 10px;
  }

  .custom-select select {
    padding: 4px 25px 4px 8px;
  }
}

/**
 * Actions
 */

.ui__actions {
  z-index: 5;
  position: absolute;
  right: 20px;
  top: 20px;
  display: table;
  table-layout: fixed;
}

/**
 * Desktop refresh
 */
.ui__meta__refresh {

  /**
   * Expanded state
   */

  &.is-showing-widget {
    .refresh__btn {
      &:before {
        display: inline-block;
      }
    }

    .refresh__list {
      display: block;
    }

    .refresh__toggle-input {
      display: block;
    }
  }
  &.is-active {
    .refresh__list .refresh__list__item__toggle {
      background-color: $blue;

      .refresh__list__item__toggle__switch {
        transform: translateX(80%);
      }
    }

    .refresh__btn {
      .btn--primary {
        background-color: $blue;
        color: white;

        &:hover {
          background-color: $blue-hover;
        }

        svg {
          fill: white;
        }
      }
    }
  }

}

/**
 * Bulk download button
 */

.ui__actions__bulk {
  display: none;
  padding-right: 10px;

  @include breakpoint(max $breakpoint) {
    display: none;
  }
}

/**
 * Right aligned map tools
 */

.ui__tools {
  z-index: 10;
  position: absolute;
  bottom: 10px;
  right: 10px;

  @include breakpoint($breakpoint) {
    bottom: 20px;
    right: 20px;
  }
}

.ui__tools__zoom {
  position: absolute;
  right: 0;
  top: -77px; // magic number: height of zoom (62), plus margin (15)

  @include breakpoint(max $breakpoint) {
    display: none;
  }
}

.ui__tools__legend {
  &.is-collapsed {
    .marker-legend__wrap {
      max-height: 0;
      overflow: hidden;
    }

    .marker-legend__head__icon {
      transform: rotate(180deg);
    }

    .marker__legend__head__title--extend {
      display: inline-block;
      visibility: visible;
    }

    .marker__legend__head__title--collapse {
      display: none;
      visibility: hidden;
    }
  }
}

/**
 * Left aligned buttons
 */

.ui__meta {
  z-index: 10;
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: table;
  table-layout: fixed;

  @include breakpoint($breakpoint) {
    bottom: 20px;
    left: 20px;
  }
}

.ui__meta__forecast {
  display: table-cell;
}

.ui__meta__forecast--mobile {
  display: none;
  padding-right: 10px;
}

.ui__tools__legend--mobile {
  display:none;

  button {
    padding:5px;
  }
}

.ui__meta__refresh, .ui__meta__share {
  display: table-cell;
  padding-right: 10px;
}

@include breakpoint(max $breakpoint) {
  .ui__tools__legend--mobile {
    display: table-cell;
  }
  .ui__tools__legend {
    display:none;
    position: absolute;
    right: 0;
    bottom: 60px;

    .marker-legend__head {
      display: none;
    }

    &.is-showing-widget {
      display:block;

      .marker-legend__wrap {
        max-height: 500px;
      }
    }
  }
  
  .ui__meta__forecast--mobile {
    display: table-cell;
  }

  .ui__meta__forecast {
    display: none;

    &.is-showing-widget {
      display: block;
      position: fixed;
      bottom: 72px;
      left: 0;
      padding: 0 10px;
    }
  }
}

.ui__meta__info,
.ui__download__info {
  display: none;
  padding-right: 10px;
}

.ui__meta__saved {
  @include breakpoint(min $breakpoint) {
    display: none;
  }
}

.ui__meta__loading {
  @include spinner;
  display: table-cell;
  padding-right: 10px;

  &:after {
    margin-left: 10px;
  }
}

/**
 * Navigation
 */

.ui__nav {
  z-index: 20;
  display: none;
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(black, .4);
  overflow: hidden;
  height: 100%;

  @include breakpoint($breakpoint) {
    width: 360px;
  }

  .nav {
    position: absolute;
  }

  .nav,
  .nav__content,
  .nav__expert__content,
  .nav__bulkdownload__content--all-groups,
  .nav__bulkdownload__content--expert-to-bulkdownload,
  .nav__bulkdownload__content--current-selection,
  .nav__bulkdownload__content--favorites,
  .nav__bulkdownload__content--parameters,
  .nav__bulkdownload__list,
  .nav__bulkdownload__list--parameters,
  .nav__bulkdownload__content--range,
  .nav__content--thematic,
  .ui__navigation--bottom,
  .ui__locations--list,
  .ui__navigation--top {
    display: none;
  }

  /**
   * Nav state: index
   */

  &.is-showing-index {
    .nav--index {
      display: block;
    }

    .nav__content--index {
      display: block;
    }
  }

  /**
   * Nav state: public
   */

  &.is-showing-public {
    .nav--map-selection {
      display: block;
    }
    .nav__content--thematic {
      display: none;
    }
    .nav__content--public {
      display: block;
    }
  }

  /**
   * Nav state: thematic
   */

  &.is-showing-thematic {
    .nav--map-selection {
      display: block;
    }
    .nav__content--thematic {
      display: block;
    }
    .nav__content--public {
      display: none;
    }
  }

  /**
   * Nav state: expert
   */

  &.is-showing-expert-favorites,
  &.is-showing-expert-all-groups,
  &.is-showing-expert-search {
    .nav--map-selection {
      display: block;
    }

    .nav__content--expert {
      display: block;
    }
  }

  &.is-showing-expert-favorites {
    .nav__expert__content--favorites {
      display: block;
    }
  }

  &.is-showing-expert-all-groups {
    .nav__expert__content--all-groups {
      display: block;
    }
    .nav__expert__content--search {
      display: block;
    }
  }

  /**
   * Nav state: parameters
   */

  &.is-showing-expert-parameters {
    .nav--expert-parameters {
      display: block;
    }

    .nav__content--parameters {
      display: block;
    }
  }

  /**
   * Nav state: bulkdownload
   */

  &.is-showing-bulkdownload-favorites {
    .nav--download-selection,
    .nav__content--bulkdownload,
    .nav__bulkdownload__content--favorites {
      display: block;
    }
  }

  &.is-showing-bulkdownload-all-groups {
    .nav--download-selection,
    .nav__bulkdownload__list,
    .nav__content--bulkdownload,
    .nav__bulkdownload__content--all-groups {
      display: block;
    }
  }

  &.is-showing-bulkdownload-expert-to-bulkdownload {
    .nav--download-selection,
    .nav__bulkdownload__content--expert-to-bulkdownload,
    .nav__bulkdownload__content--current-selection {
      display: block;
    }
  }

  &.is-showing-bulkdownload-parameters {
    .nav__content--bulkdownload,
    .nav--download-selection,
    .nav__bulkdownload__content--parameters,
    .js-nav__group-selection__bulkdownload,
    .nav__bulkdownload__list--parameters {
      display: block;
    }

    .js-nav__map-selection__bulkdownload__tabs {
      display: none;
    }
  }

  &.is-showing-bulkdownload-range {
    .nav__content--bulkdownload,
    .nav--download-selection,
    .nav__bulkdownload__content--range {
      display: block;
    }

    .js-nav__map-selection__bulkdownload__tabs,
    .nav__expert__searchbar {
      display: none;
    }
  }
}

/**
 * Details
 */

.ui__details {
  z-index: 20;
  display: none;
  background-color: white;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.ui__details__body {
  padding: 0 20px;
}

.ui__details__footer {
  padding: 0 20px 20px 20px;
}

/**
 * Map info
 */

.ui__map-info {
  z-index: 20;
  display: none;
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 700px;
  max-width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.ui__map-info__body {
  @include body;
  padding: 0 20px;
}

.ui__map-info__footer {
  padding: 20px;
}

/**
 * Nav info
 */

.ui__nav-info {
  z-index: 20;
  display: none;
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 700px;
  max-width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include breakpoint(max 1060px) {
    width: 100%;
  }
}

.ui__nav-info__body {
  @include body;
  padding: 0 20px;
}

.ui__nav-info__footer {
  padding: 20px;
}

/**
 * The map
 */

.ui__map {
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/**
 * The reset label
 */

.ui__reset {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .4);
  width: 100%;
  display: none;
  transition: background-color $timing;

  &:focus {
    background-color: rgba(0, 0, 0, .05);
  }
}

.ui__navigation--bottom {
  display: none;
  padding: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  height: 70px;
  width: 100%;
  background: #f4f3f2;
  border-top: 1px solid #ccc;
  box-shadow: 0 0 12px -3px #888;

  .btn--secondary {
    width: auto;
    float: right;
  }

  .btn--tertiary {
    width: auto;
  }

  .location--count {
    display: none;
    @include breakpoint($breakpoint) {
      display: block;
    }
  }
}

.nav--download-selection {
  .nav__bulkdownload__content--range {
    .container {
      @include clearfix;
      width: 100%;
      margin-bottom: 25px;

      :nth-child(even) {
        margin-left: 1%;
      }

      :nth-child(odd) {
        margin-right: 1%;
      }

      .half-size {
        float: left;
        width: 49%;

        label {
          font-weight: bold;
          padding-bottom: 5px;
          display: block;
        }

        input {
          width: 100%;
          padding-left: 5px;
          border: 1px solid #b4b4b4;
          -webkit-box-shadow: inset 1px 1px 3px 0 rgba(103, 103, 103, .1);
          box-shadow: inset 1px 1px 3px 0 rgba(103, 103, 103, .1);
          -webkit-border-radius: 3px;
          border-radius: 3px;
          height: 40px;
          line-height: 40px;
        }
      }
    }

    button.btn--secondary.js-page.js-nav__bulkdownload-parameters__form__submit {
      width: 100%;
      margin: 10px 0;

      &:disabled {
        opacity: .5;

        &:hover {
          background-color: $yellow;
        }
      }
    }
  }
}

/**
 * Fix for info icon on index page.
 */

button.list__item__link {
  &[data-href='/nav/info/'] {
    height: 49px;

    .list__item__label {
      position: relative;
      bottom: 2px;
    }
  }
}

/**
 * The polygon selection list
 */

.bulkdownload-map-type.has-map-info .ui__select{
  display:block;
}
.details__actions__range {
  display: inline-flex;

  .custom-select {
    display: inline-block;
    width: calc(100% - 34px);
  }
}

.js-add__polygon{
  margin-left:4px;
  background-color: $bg-blue-2;
  border-radius:3px;
  display:inline-block;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 12px;
    height: 12px;
    fill: #fff;
  }

  &:hover {
    background-color: #005b94;
  }

  &:disabled {
    &:hover {
      background-color: $bg-blue-2;
    }
    svg {
      opacity: .5;
    }
  }
}