/**
 * Primary button
 */

.btn--primary-wrapper {
  position: relative;
  display: inline-block;
}

.btn--primary {
  border-radius: 3px;
  line-height: 0;
  font-size: $text-m;
  box-shadow: 0 1px 3px 0 rgba(black, .4);
  background-color: white;
  color: $text;
  transition: background-color $timing, color $timing;
  padding: 12px;

  &:hover {
    color: $text;
    background-color: $white-hover;

    svg {
      fill: $blue-hover;
    }
  }

  &:focus svg {
    outline: $focus-outline;
  }

  .btn--primary__icon {
    vertical-align: middle;
    display: inline-block;
    fill: $blue;
    transition: fill $timing;
  }

  /**
   * Active state
   */

  &.is-active {
    background-color: $blue;
    color: white;

    &:hover {
      background-color: $blue-hover;
    }

    svg {
      fill: white;
    }
  }
}

.btn--primary__content {
  margin-left: 8px;
  vertical-align: middle;
}


.btn--primary--icon-only {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 10px;

  svg {
    width: 100%;
    height: 100%;
  }
}

/**
 * icon sizes
 */

.btn--primary__icon--external {
  width: 18px;
  height: 16px;
}

.btn--primary__icon--star {
  width: 20px;
  height: 20px;
}

.btn--primary__icon--info {
  width: 20px;
  height: 20px;
}

.btn--primary__icon--refresh {
  width: 20px;
  height: 20px;
}



/**
 * Badge above an icon button (¹)
 */

.btn__badge {
  @include badge;
  position: absolute;
  top: -12px;
  right: -8px;
}