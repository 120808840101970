.marker-legend {
  width: 200px;
  box-shadow: 0 1px 3px 0 rgba(black, .4);
  font-size: $text-s;
  background-color: white;
  position: relative;
  overflow: hidden;

  // @include breakpoint($breakpoint) {
  //   width: 200px;
  // }
}

.marker-legend__wrap {
  max-height: 500px;
  transition: max-height $timing;
}

.marker-legend__head {
  padding: 10px 12px;
  border-bottom: 1px solid $border-grey2;
  width: 100%;
  font-size: $text-m;
  background-color: white;
  transition: background-color $timing;

  &:hover {
    background-color: $white-hover;

    .marker-legend__head__icon {
      fill: $text;
    }
  }

  &:focus svg {
    outline: $focus-outline;
  }
}

.marker-legend__tabs {
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;

  .active {
    border-bottom-color: #fff;
    background-color: inherit;
    border: 0;
  }

  .inactive {
    background-color: #e5e5e5;
    border-right: 1px solid $border-grey2;
    border-bottom: 1px solid $border-grey2;
    border-left: 1px solid $border-grey2;
  }

  .marker__legend__btn {
    color: $blue;
    display: flex;
    justify-content: center;
    width: 20%;
    height: 100%;
    flex-grow: 1;

    .custom-select__icon {
      display: block;
      position: relative;
    }

    svg {
      max-height: 20px;
      max-width: 20px;
    }
    &:focus {
      outline: $focus-outline;
    }
  }
}

.marker-legend__head__heading {
  display: inline;
}

.marker__legend__head__title {
  @include accessibility;

  &.marker__legend__head__title--extend {
    display: none;
    visibility: hidden;
  }

  &.marker__legend__head__title--collapse {
    display: inline-block;
    visibility: visible;
  }
}

.marker-legend__head__icon {
  float: right;
  margin-top: 2px;
  position: relative;
  width: 14px;
  height: 14px;
  fill: $text-grey2;
  transition: fill $timing;
}

.marker-legend__content__title {
  font-weight: bold;
  margin-bottom: 10px;
}

.marker-legend__input {
  display: none;
}

.is-user-theme .marker-legend__content {
  min-height: 250px;
}

.marker-legend__content {
  font-size: $text-s;
  padding: 12px;
  padding-bottom: 15px;

  @include breakpoint(max $breakpoint) {
    max-height: 55vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  &.is-hidden {
    display: none;
  }
}

.marker-legend__content__list__item {
  margin-bottom: 10px;
  display: table;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

.marker-legend__content__list__item__indicator {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 6px;
  vertical-align: middle;
  float: left;

  svg {
    width: 12px;
    height: 12px;
    fill: $text-grey;
  }
}

.marker-legend__content__list__item__label {
  display: inline-block;
  vertical-align: middle;
  width: 85%;
  float: left;
}

.marker-legend__foot {
  position: relative;
  display: block;
  padding: 10px;
  border-top: 1px solid $border-grey;
  color: $link-color;
}


/**
 * Legend with gradient styling
 */

.marker-legend__content__list-wrapper.is-gradient {
  display: block;
  width: 100%;
  position: relative;

  .marker-legend__content__list {
    padding-left: 20px;
    vertical-align: top;
  }
}

.marker-legend__content__gradient {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 12px;

  canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}


/**
 * Forecast wrapper
 */

.marker-legend__forecast-wrapper {
  margin-top: 10px;
}