/**
 * public list
 */

.list--public__item {
  margin-bottom: 10px;
  user-select: none;
}

.list--public__item__link {
  display: table;
  width: 100%;
  height: 60px;
  padding: 10px;
  color: $text;
  font-size: $text-l;
  border-radius: 3px;
  border: 1px solid $border-grey2;
  transition: border-color $timing;
  text-align: left;

  &:focus {
    outline: $focus-outline;
  }

  &:hover {
    color: $text;
    text-decoration: none;
    border-color: $blue;
  }
}

.list--public__item__icon {
  display: table-cell;
  vertical-align: middle;
  width: 50px;
  color: $blue;

  svg {
    display: block;
    vertical-align: middle;
    fill: $blue;
    width: 40px;
    height: 40px;
  }
}

.list--public__item__label {
  display: table-cell;
  vertical-align: middle;
  padding-left: 10px;
}