/**
 * Custom select box styling
 * https://github.com/filamentgroup/select-css/blob/master/src/select.css
 */


/* Container used for styling the custom select, the buttom class below adds the bg gradient, corners, etc. */
.custom-select {
  position: relative;
  display: table;
  width: 100%;
  padding: 0;
  background-color: #fafafa;
  background-image: linear-gradient(-180deg, #fafafa 51%, #ececec 100%);
  border: 1px solid $border-grey3;
  border-radius: 3px;
  font-size: $text-m;
  font-weight: normal;

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }
}

/* Custom arrow sits on top of the select - could be an image, SVG, icon font, etc. or the arrow could just baked into the bg image on the select. Note this si a 2x image so it will look bad in browsers that don't support background-size. In production, you'd handle this resolution switch via media query but this is a demo. */
.custom-select__arrow {
  position: absolute;
  width: 25px;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  text-align: center;
  border-left: 1px solid $border-grey3;
  /* This hack makes the select behind the arrow clickable in some browsers */
  pointer-events: none;

  svg {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 100%;
  }
}

.details__actions__range .custom-select__arrow svg {
  height: 30px;
}

.custom-select__icon {
  width: 40px;
  padding: 4px 5px 3px 10px;
  vertical-align: middle;
  display: block;
  position: absolute;
  pointer-events: none;
  color: $blue;
  .is-showing-public .custom-select__wrapper select {
    padding-left: 45px;
  }

  .custom-select__icon {
    display: block;
    position: absolute;
    pointer-events: none;
  }

  @include breakpoint(min $breakpoint) {
    padding-top: 8px;
    padding-bottom: 7px;
  }

  svg {
    max-height: 25px;
    max-width: 25px;
    vertical-align: middle;
    display: block;
  }
}

.custom-select__wrapper {
  display: table-cell;
  vertical-align: middle;
}

/* This is the native select, we're making everything but the text invisible so we can see the button styles in the wrapper */
.custom-select select {
  width: 100%;
  margin: 0;
  background: none;
  border: 1px solid transparent;
  outline: none;
  box-sizing: border-box;
  /* Remove select styling */
  appearance: none;
  /* Font size must the 16px or larger to prevent iOS page zoom on focus */
  font-size: 1em;
  /* General select styles: change as needed */
  padding: 4px 25px 4px 8px;
  line-height: 23px;

  &:focus {
    outline: $focus-outline;
  }
}


/* Set options to normal weight */
.custom-select option {
  font-weight: normal;
  padding: 2px 8px 2px 8px;
  color: $text;
}

.custom-select optgroup {
  color: $text-grey2;
  padding: 2px 8px 2px 4px;
}

.is-user-public .ui__search .custom-select__wrapper select {
  padding-left: 45px;
}

