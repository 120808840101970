.btn--tertiary {
  display: block;
  background-color: $bg-grey1;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 15px;
  border-radius: 3px;
  font-size: 18px;
  transition: opacity $timing, background-color $timing;

  &:focus {
    outline: $focus-outline;
  }

  &:hover {
    background-color: $bg-grey2;
  }
}