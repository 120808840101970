.nav__expert__searchbar {
  margin-bottom: 35px;
  border: 1px solid $border-grey3;
  box-shadow: inset 1px 1px 3px 0 rgba(103, 103, 103, .1);
  border-radius: 3px;
  height: 40px;
  line-height: 40px;
  padding: 0;
  position: relative;

  /**
   * Different view states
   */


  /**
   * there is input
   */
  
  &.is-typing,
  &.has-input {

    // show the reset button
    .nav__expert__searchbar__clear {
      width: 40px;
    }

    .nav__expert__searchbar__button {
      pointer-events: auto;
      border-left: 1px solid $border-grey3;

      > svg {
        opacity: 1;
      }
    }
  }
}

/**
 * the input field itself
 */

.nav__expert__searchbar__field {
  padding: 8px;
  font-size: $text-m;
  display: table-cell;
  width: 85%;
  border: 0;
  background-color: transparent;
}

/**
 * Reset button
 */

.nav__expert__searchbar__clear {
  position: absolute;
  right: 40px;
  top: 0;
  bottom: -1px;
  width: 0;
  transition: width $timing ease-out;
  overflow: hidden;
  text-align: center;

  svg {
    background-color: white;
    fill: $text-grey3;
    transition: fill $timing;
    vertical-align: middle;
  }

  &:focus {
    outline: $focus-outline;
  }

  &:focus,
  &:hover {
    svg {
      fill: $text;
    }
  }
}


/**
 * Submit button
 */

.nav__expert__searchbar__button {
  overflow: hidden;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: -1px;
  bottom: -1px;
  display: table-cell;
  border-left: 1px solid transparent;
  transition: border-color $timing;
  text-align: center;
  width: 40px;

  &:focus {
    outline: $focus-outline;
  }

  &:focus,
  &:hover {
    svg {
      fill: $text;
    }
  }

  > svg {
    opacity: .8;
    fill: $text-grey;
    vertical-align: middle;
    width: 17px; // for firefox 1px more
    height: 16px;
    transition: opacity .15s, background-color .15s;
  }
}