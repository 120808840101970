/**
 * panel topbar styling
 */

.panel__topbar {
  @include clearfix;
  position: relative;
  background: $bg-grey2;
  box-shadow: 0 1px 2px 0 rgba(black, .15);
  padding: 12px 10px;
  height: 50px;
  margin-bottom: 20px;
}

.panel__topbar__label {
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  display: block;
  font-size: $text-xxl;
  color: $title-blue;
}

.panel__topbar__button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 14px 15px;

  svg {
    width: 20px;
    height: 20px;
  }
}