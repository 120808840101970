/**
 * generic list styling
 */

.list {
  margin-left: -15px;
  margin-right: -15px;
  font-size: $text-l;
}

.list__item {
  padding-left: 15px;
  padding-right: 15px;
  transition: background-color $timing;
  user-select: none;

  &:hover {
    .list__item__link {
      border-color: $bg-grey2;
      z-index: 1;
      text-decoration: none;
    }
  }

  &.is-hidden {
    display: none !important;
  }
}

.list__item__link {
  overflow: hidden;
  display: block;
  width: calc(100% - 38px);
  position: relative;
  margin-bottom: -1px;
  color: $text;
  padding: 15px 0 15px 11px;
  transition: border-color $timing;

  &:focus {
    outline: $focus-outline;
  }

  &:hover {
    text-decoration: none;
    color: $text;
  }
}

.nav__bulkdownload__list .list__item__link {
  width: calc(100% - 76px);
}

.nav__content--index {
  .list__item {
    padding-left: 15px;
    padding-right: 15px;
    transition: background-color $timing;
    user-select: none;

    &:hover {
      background-color: $bg-grey2;

      .list__item__link {
        border-color: $bg-grey2;
        z-index: 1;
      }
    }

  }

  .list__item__link {
    width: 100%;
    border-bottom: 1px solid $border-grey2;
    border-top: 1px solid $border-grey2;
    padding: 15px 40px 15px 0;
    transition: border-color $timing;

    &:focus {
      outline: $focus-outline;
    }

    &:hover {
      text-decoration: none;
      color: $text;
    }
  }
}


/**
 * default list
 */

.list__item__arrow {
  position: relative;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 15px;
}
.list__item__arrow.uncollapsed {
  display: inline-block !important;
}
.list__item__arrow.collapsed {
  display: none !important;
}

li > button.open > .list__item__arrow.collapsed {
  display: inline-block !important;
}
li > button.open > .list__item__arrow.uncollapsed {
  display: none !important;
}

ul.list.list--all-groups > li {
  display: none;
}

ul.list--parameters > li {
  display: none;
}

ul.list.list--all-groups.collapsed {
  > li {
    display: block;
  }
}

li.list--parameters__item.is-hidden {
  display: none !important;
}

.collapse-0 {
  margin-left: -15px;
  margin-right: -15px;
}

ul.list--parameters.collapsed {
  > li {
    display: block;
    &:hover{
      background-color:$bg-grey2;
    }
  }
}


/**
 * icon list
 */

.list--icon {

  .list__item__icon {
    margin-right: 8px;
    fill: $blue;
    width: 16px;
    height: 16px;
  }
}

.list__item__icon--external,
.list__item__icon--return {
  vertical-align: text-top;
}

/**
 * result list
 */

.list--results {
  .list__item {
    font-size: $text-s;
  }

  .list__item__heading {
    @include h4;
    display: inline-block;
  }
}


/**
 * favorite list
 */

.list--all-groups {
  position: relative;

  .list__item {
    background-color:transparent !important;
    .list__item__link {
      &:hover {
        background-color: $white-hover;
      }
    }
  }

  @for $i from 1 through 3 {
    .collapse-#{$i} {
      margin-left: 0;
      margin-right: 0;


      .list__item,
      .list--parameters__item {
        position: relative;
        padding-left: 15px;
        &:hover {
          background-color:transparent;
        }
        &:first-child {
          margin-top:3px;
        }

        .list--parameters__checkbox {
          left: 30px;
        }

        .list__item__link {
          //width: 100%;
          &:hover {
            background-color: $white-hover;
          }
          border-left: 1px solid #ccc;
        }
      }
    }
  }
  .list__item__check {
    display: block;
    width: 38px;
    height: 47px;
    position: absolute;
    right: 38px;
    top: 0px;
    text-align:center;
    padding:14px 13px;

    &:hover {
      background: $white-hover;
      cursor:pointer;
    }
    input:focus {
      outline: $focus-outline;
    }
  }
  .list__item__star {
    padding: 16px 12px;
    position: absolute;
    right: 0;
    top: 0;
    &:hover {
      background: $white-hover;
    }
    &:focus svg {
      outline: $focus-outline;
    }
  }

  .list__item__star__icon {
    width: 14px;
    height: 14px;
    fill: $bg-grey4;
  }

  .list__item {
    padding-left: 0;
    padding-right: 0;
    position: relative;

    &:hover {
      background-color: $white-hover;
    }

    &.is-saved {
      .list__item__star__icon {
        fill: $star-active;
      }
    }
  }
}