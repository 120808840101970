/**
 * The Chart legend describes the chart colors / lines.
 */

.chart-legend {
  position: relative;
}

.chart-legend__list {
  border: 1px solid $border-grey;
  padding: 8px 12px 2px 12px;
}

.chart-legend__list__item {
  display: inline-block;
  margin: 0 15px 6px 0;

  svg {
    vertical-align: middle;
  }
}

.chart-legend__list__item__icon {
  vertical-align: middle;
  display: inline-block;
  margin-right: 5px;
  width: 20px;
  height: 5px;
}

.chart-legend__list__item__square {
  vertical-align: baseline;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 5px;
}

.chart-legend__list__item__label {
  font-size: $text-s;
}