/**
 * specific margin for nav--expert
 */

.nav--expert {
  .nav__title {
    margin-bottom: 15px;
  }
}


/**
 * secondary tabs
 */

.nav__secondary-tabs {
  margin-bottom: 15px;
}


/**
 * expert area list
 */

.nav__expert__list {
  @include spinner;
  margin-bottom: 20px;
  position: relative;

  &.is-loading + .nav__expert__list-reset,
  &.has-error + .nav__expert__list-reset {
    display: none;
  }
}

/**
 * expert area text
 */

.nav__expert__title {
  @include h3($text);
  margin-bottom: 15px;
}

.nav__expert__description {
  margin-bottom: 25px;
}


/**
 * Reset
 */

.nav__expert__list-reset {
  @include link;
}

/**
 * Parameters
 */

.nav__content--parameters {

  &.is-complete {

    .nav__parameters__btn button {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.nav__parameters__btn {
  position: fixed;
  padding: 15px 0;
  bottom: 0;
  width: 313px;
  background: #fff;
  z-index: 999;
  button {
    opacity: .5;
    transition: opacity .15s;
    pointer-events: none;
    width:100%;
  }
}
.nav__parameters__btn--split {
  button.btn--tertiary, button.btn--secondary, button.btn--primary{
      width:50%;
      display:inline-block;
  }
}

.nav__parameters__toolbox {
  button.btn--tertiary, button.btn--secondary, button.btn--primary{
    width:49%;
    display:inline-block;
    font-size: 14px !important;
  }
  position: fixed;
  bottom: 0;
  left: 0;
  width: 345px;
  background: #fff;
  z-index: 999;
  display:none;
  padding: 6px 15px;
  box-shadow: 0 -3px 7px -5px #000;
  @include breakpoint(max $breakpoint) {
    width: 100%;
  }
  &.is-active {
    display:block;
  }
}