/**
 * The details page (the overlay with the charts and data)
 * This is used for alignment and page-specific 
 * styling of the components,
 * not for the components themselves.
 */

.details__title {
  @include h2;
  color: $title-blue;
  margin-bottom: 15px;
}

.details__description {
  margin-bottom: 25px;
}

.details__description__icon {
  vertical-align: middle;
  display: inline-block;
  margin-right: 6px;
  width: 16px;
  height: 16px;
}

.details__description__location {
  border-right: 1px solid $border-grey2;
  padding-right: 8px;
  margin-right: 8px;
  display: inline-block;
}


/**
 * The chart actions
 */

.details__actions {
  margin-bottom: 20px;
  height: 100%;

  @include breakpoint(min $breakpoint) {
    display: table;
    width: 100%;
    table-layout: fixed;
  }
}

.details__actions__view {
  margin-bottom: 15px;

  @include breakpoint(min $breakpoint) {
    width: 250px;
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    margin-bottom: 0;
  }
}

.details__actions__range {
  width: 100%;

  @include breakpoint(min $breakpoint) {
    display: table-cell;
    vertical-align: middle;
    width: 280px;
    padding: 0 15px;
    height: 100%;
    margin-bottom: 0;

  }
}

.details__actions__buttons {
  @include breakpoint(min $breakpoint) {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    height: 100%;
    width: 100%;
  }
}


/**
 * Body
 */

.details__forecast-message {
  @include body;
}


/**
 * Chart / table
 */

.details__chart {
  height: auto; // TODO: check if set in the code
  width: 100%;
  overflow: hidden;
  margin-bottom: 0;

  .chart-legend__list__item__square {
    display: none !important;
  }
}

.details__chart__chart {
  display: block;
  width: 100%;
  height: 450px;
  position: relative;

  .highcharts-container {
    top: inherit !important;

    .highcharts-tooltip {
      @include breakpoint(min $breakpoint) {
        left: -99999px;
        top: inherit !important;
        bottom: 0;
      }
    }
  }
}


.details__chart__limits {
  padding: 5px;
  background-color: rgba(white, .6);
  position: absolute;
  top: 450px;
  left: 0;
  user-select: none;
}

.details__chart__limits__input {
  margin-right: 5px;
  vertical-align: middle;
  display: inline-block;

  &:focus {
    outline: $focus-outline;
  }
}

/**
 * Switch different views
 * rest of state is defined in ui.scss
 */

.details__chart-share {
  display: none;
}

.chart-error {
  display: none;
}


/**
 * Details states
 */

.ui__details__body__item {
  position: relative;

  // loading
  &.is-showing-loader {

    .details__chart__limits {
      display: none !important;
    }

    .details__chart__chart,
    .details__table .chart-table-wrapper,
    .details__hilow .chart-table-wrapper{
      @include spinner;

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
      }
    }
  }

  // has tools disabled
  &.has-tools-disabled {
    .chart-actions__refresh,
    .chart-actions__export,
    .details__actions__view {
      opacity: .5;
      pointer-events: none;
      cursor: not-allowed; // doesn't really work with pointer events none
    }

    .chart-actions__export {
      &:hover {
        .chart-actions__dropdown {
          display: none;
        }
      }
    }
  }

  // has error
  &.is-showing-error {
    .details__chart__chart,
    .chart-legend,
    .chart-table-wrapper {
      display: none;
    }

    .chart-error {
      display: block;
    }

    // style favorites button for error state
    .details__actions--error {
      margin-bottom: 0;

      .chart-actions__save {
        display: none;

        &.is-saved {
          display: block;
          margin-bottom: 10px;
        }
      }

      .details__actions {
        margin-bottom: 10px;
      }

      .details__actions__buttons {
        text-align: left;
      }
    }
  }

  // show chart
  &.is-showing-chart {
    .details__chart {
      height: auto;
      margin-bottom: 30px;
    }
  }


  // show embed
  &.is-showing-embed {
    .details__chart-share--embed {
      display: block;

      @include breakpoint(min $breakpoint) {
        display: table;
      }
    }
  }

  // show link
  &.is-showing-link {
    .details__chart-share--link {
      display: block;

      @include breakpoint(min $breakpoint) {
        display: table;
      }
    }
  }
}

.is-showing-popout {
  .ui__details__popout {
    display: block;
    width: 900px;
    background-color: rgba(0, 0, 0, .5);
    z-index: 21;
    max-width: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;

    .ui__details__popout__window {
      background: #fff;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
      top: 10%;
      position: relative;

      .ui__details__popout__body {
        padding: 0 20px 20px 20px;

        h4.formlabel {
          @include h4;
          margin-bottom: 15px;
        }

        .container {
          @include clearfix;
          width: 100%;
          margin-bottom: 10px;

          :nth-child(even) {
            margin-left: 1%;
          }

          :nth-child(odd) {
            margin-right: 1%;
          }

          .label-column {
            float: left;
            width: 39%;
            text-align:left;

            label {
              font-weight: bold;
              line-height: 30px;
              padding-bottom: 0;
              display: block;
            }
          }

          .input-column {
            float: left;
            width: 59%;

            input {
              width: 100%;
              padding-left: 5px;
              border: 1px solid #b4b4b4;
              -webkit-box-shadow: inset 1px 1px 3px 0 rgba(103, 103, 103, .1);
              box-shadow: inset 1px 1px 3px 0 rgba(103, 103, 103, .1);
              -webkit-border-radius: 3px;
              border-radius: 3px;
              height: 30px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}

.details__info-button {
  margin-bottom: 30px;
}

.ui__details__popout {
  display: none;
}
