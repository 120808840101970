/**
 * map forecast
 */

.map-forecast {
  top: 1px;
  position:relative;

  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {   // hack for ie11
    top: 16px;
  }
}

.map-forecast__bar {
  height: 40px;
  font-size: $text-m;
  box-shadow: 0 1px 3px 0 rgba(black, .4);
  display: flex;
  color: $text;
  transition: background-color $timing, color $timing;

  .map-forecast__bar__item {
    cursor:pointer;
    display:flex;
    position:relative;
    flex-flow: row wrap;
    background:white;
    height:100%;
    max-width: 100px;
    padding: 4px 15px;
    border-left: 1px solid $border-grey2;
    text-align:center;
    justify-content: center;

    &:first-of-type {
      border-radius: 3px 0 0 3px;
      border:none;
    }

    &:last-of-type {
      border-radius: 0 3px 3px 0;
    }

    .map-forecast__bar__item__label {
      display: flex;
      align-items: center;
      justify-content: center;
      &.subtitel {
        color:$bg-blue-2;
        font-size:0.8em;
      }
    }

    &:hover {
      background-color:$white-hover;
    }

    &.is-active {
      cursor:initial;
      background-color: $bg-blue-2;
      color:white;
      border: none;

      .map-forecast__bar__item__label, .map-forecast__bar__item__label.subtitel {
        color: white;
      }
    }

    .map-forecast__bar__item__indicator {
      border-radius: 100%;
      width: 12px;
      height: 12px;
      box-shadow: 0 1px 3px 0 rgba(black, .4);
      position: absolute;
      right: 2px;
      top: -6px;
    }
  }

  @include breakpoint(max $breakpoint) {
    .map-forecast__bar__item {
      padding: 4px 8px;

      .map-forecast__bar__item__label {
        font-size: 0.8em;

        &.subtitle {
          font-size: 0.6em;
        }
      }
    }
  }

  .map-forecast__bar__item--active ~ .map-forecast__bar__item {
    border:none;
  }
}