/**
 * nav topbar styling
 */

.nav__topbar {
  @include clearfix;
  position: relative;
  background: $bg-grey2;
  box-shadow: 0 1px 2px 0 rgba(black, .15);
  padding: 12px 10px;
  height: 50px;
  margin-bottom: 20px;
}

.nav__topbar__label {
  width: 100%;
  left: 0;
  right: 0;
  text-align: center;
  display: block;
  font-size: 19px;
  color: $title-blue;

  &:focus {
    outline: $focus-outline;
  }
}

.nav__topbar__link {
  position: absolute;
  left: 0;
  top: 0;
  padding: 17px 15px;
  font-size: 12px;
  vertical-align: middle;
  display: inline-block;

  * {
    color: $dark-blue;
    fill: $dark-blue;
  }
}

.nav__topbar__button {
  position: absolute;
  right: 0;
  top: 0;
  padding: 14px 15px;

  svg {
    width: 20px;
    height: 20px;
    fill: $dark-blue;
  }
}

/* stopnav */
.ui__navigation--top {
  @include clearfix;
  z-index: 19;
  position: fixed;
  top: 0;
  left: 0;
  background: $bg-grey2;
  box-shadow: 0 1px 2px 0 rgba(black, .15);
  padding: 12px 10px;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  .ui__navigation--top__link {
    position: absolute;
    top: 15px;
  }
  h1 {
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    display: block;
    font-size: 19px;
    color: $title-blue;

    &:focus {
      outline: $focus-outline;
    }
  }
  display: none;
}