.search__select {
  
  @include breakpoint(max $breakpoint) {
    @include tooltip--bottom;
  }

  @include breakpoint(min $breakpoint) {
    @include tooltip--right;
  }

  select {
    min-height: 32px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    @include breakpoint(min $breakpoint) {
      min-height: 40px;
    }
  }
}